<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <!-- <h4 class="mb-0 font-size-18"> {{updated_consumerData.id? 'Update Consumer': 'Create Consumer'}} </h4> -->
        <div class="page-title-right">
          <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        </div>
        <!-- <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/consumers'">Consumers</a>
            </li>

            <li class="breadcrumb-item">Create Consumer
            </li>

          </ol>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <form class="form-horizontal" [formGroup]="consumersForm">
            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Title<span class="required">*</span> </label>
                  <ng-select [items]="title_List
                    " formControlName="title" (change)="valueChange($event)" [multiple]="false" bindLabel="name"
                    bindValue="name" [placeholder]="'Select...'"></ng-select>
                  @if(submitted && consumersControls['title'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['title'].errors['required']){
                    <div>{{prifileValidations.consumersPage.title}}</div>}
                  </div>}

                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="">First Name <span class="required">*</span></label>
                  <input type="text" class="form-control" id="firstName" formControlName="firstName" name="firstName"
                    placeholder="Enter First Name">
                  @if(submitted && consumersControls['firstName'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['firstName'].errors['required']){
                    <div>{{prifileValidations.ProfilePage.firstName.firstName_required_Validations}}</div>}

                    @if(consumersControls['firstName'].errors['minlength']){
                    <div>{{prifileValidations.ProfilePage.firstName.min_required_Validations}}</div>
                    }
                    @if(consumersControls['firstName'].errors['maxlength']){
                    <div>{{prifileValidations.ProfilePage.firstName.min_required_Validations}}</div>
                    }
                    @if(consumersControls['firstName'].errors['pattern']){
                    <div>{{prifileValidations.ProfilePage.firstName.allow_spaces}}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Last Name <span class="required">*</span></label>
                  <input type="text" class="form-control" id="lastName" formControlName="lastName" name="lastName"
                    placeholder="Enter last Name">
                  @if(submitted && consumersControls['lastName'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['lastName'].errors['required']){
                    <div>{{prifileValidations.ProfilePage.lastName.lastName_required_Validations}}</div>}
                    @if(consumersControls['lastName'].errors['minlength']){
                    <div>{{prifileValidations.ProfilePage.lastName.min_required_Validations}}</div>
                    }
                    @if(consumersControls['lastName'].errors['maxlength']){
                    <div>{{prifileValidations.ProfilePage.lastName.min_required_Validations}}</div>
                    }
                    @if(consumersControls['lastName'].errors['pattern']){
                    <div>{{prifileValidations.ProfilePage.lastName.allow_spaces}}</div>}
                  </div>}
                </div>
              </div>



              <!-- <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number - Primary<span class="required">*</span></label>
                  <input type="text" class="form-control" id="mobile_Primary_Number"
                    formControlName="mobile_Primary_Number" name="mobile_Primary_Number"
                    placeholder="Enter Primary Number">
                  @if(submitted && consumersControls['mobile_Primary_Number'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['mobile_Primary_Number'].errors['required']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_required_validations}}</div>}
                    @if(consumersControls['mobile_Primary_Number'].errors['pattern']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_format}}</div>}
                    @if(consumersControls['mobile_Primary_Number'].errors['maxlength']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div>}
                  </div>}
                </div>
              </div> -->


              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number - Primary<span class="required">*</span></label>

                  <!-- Country Code Dropdown using ng-select -->
                  <div class="input-group">

                    <!-- ng-select with smaller width -->
                    <ng-select [items]="configData?.data?.countryCodes" bindLabel="name" bindValue="id"
                      formControlName="countryCode" placeholder="Select Country" class="ng-select-custom-size"
                      (change)="PrimaryvaliadationMobile($event)">
                      <!-- Template for displaying the selected item -->
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.code }})</span>
                      </ng-template>

                      <!-- Template for displaying the options in the dropdown -->
                      <ng-template ng-option-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.code }})</span>
                      </ng-template>
                    </ng-select>

                    <!-- Mobile Number Input with larger width -->
                    <input type="text" class="form-control phone-number-input" id="mobile_Primary_Number"
                      formControlName="mobile_Primary_Number" placeholder="Enter Mobile Number">
                  </div>

                  <div *ngIf="submitted && consumersControls['countryCode'].errors" class="invalid-feedback">
                    <div *ngIf="consumersControls['countryCode'].errors['required']">
                      {{ prifileValidations.consumersPage.countryCode.countryCode_required_validations }}
                    </div>
                  </div>
                  <!-- Validation Feedback -->
                  @if(submitted && consumersControls['mobile_Primary_Number'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['mobile_Primary_Number'].errors['required']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_required_validations}}</div>}
                    @if(consumersControls['mobile_Primary_Number'].errors['pattern']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_format}}</div>}
                    @if(consumersControls['mobile_Primary_Number'].errors['maxlength']){
                    <div>{{valiadationMessage}}</div>
                    <!-- <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div> -->
                    }
                    @if(consumersControls['mobile_Primary_Number'].errors['minlength']){
                    <div>{{valiadationMessage}}</div>
                    <!-- <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div> -->
                    }
                  </div>}
                </div>
              </div>




              <!-- 
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number - Secondary</label>
                  <input type="text" class="form-control" id="mobile_Secondar_Number"
                    formControlName="mobile_Secondar_Number" name="mobile_Secondar_Number"
                    placeholder="Enter Secondary Number">
                  @if(submitted && consumersControls['mobile_Secondar_Number'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['mobile_Secondar_Number'].errors['pattern']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_format}}</div>}
                    @if(consumersControls['mobile_Secondar_Number'].errors['maxlength']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div>}
                  </div>}


                  <div class="invalid-feedback">
                    <div
                      *ngIf="consumersControls['mobile_Secondar_Number'].value &&consumersControls['mobile_Primary_Number'].value&&consumersControls['mobile_Secondar_Number'].value == consumersControls['mobile_Primary_Number'].value">
                      <div>{{prifileValidations.ProfilePage.mobile_validation.same_MobileNumber}}</div>
                    </div>

                  </div>
                </div>
              </div> -->


              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Mobile Number - Secondary </label>

                  <!-- Country Code Dropdown using ng-select -->
                  <div class="input-group">

                    <!-- ng-select with smaller width -->
                    <ng-select [items]="configData?.data?.countryCodes" bindLabel="name" bindValue="id"
                      formControlName="secondaryCountryCode" placeholder="Select Country" class="ng-select-custom-size"
                      (change)="SecondaryvaliadationMobile($event)">
                      <!-- Template for displaying the selected item -->
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.code }})</span>
                      </ng-template>

                      <!-- Template for displaying the options in the dropdown -->
                      <ng-template ng-option-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.code }})</span>
                      </ng-template>
                    </ng-select>

                    <!-- Mobile Number Input with larger width -->
                    <input type="text" class="form-control phone-number-input" id="phoneNumber"
                      formControlName="mobile_Secondar_Number" placeholder="Enter Mobile Number">
                  </div>

                  <div *ngIf="submitted && consumersControls['secondaryCountryCode'].errors" class="invalid-feedback">
                    <div *ngIf="consumersControls['secondaryCountryCode'].errors['required']">
                      {{ prifileValidations.consumersPage.countryCode.secoundary_countryCode_required_validations }}
                    </div>
                  </div>
                  <!-- Validation Feedback -->
                  @if(submitted && consumersControls['mobile_Secondar_Number'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['mobile_Secondar_Number'].errors['pattern']){
                    <div>{{prifileValidations.consumersPage.mobile_validation.mobile_format}}</div>}
                    @if(consumersControls['mobile_Secondar_Number'].errors['maxlength']){
                    <div>{{valiadationMessage}}</div>
                    <!--  <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div> -->
                    }
                    @if(consumersControls['mobile_Secondar_Number'].errors['minlength']){
                    <div>{{valiadationMessage}}</div>
                    <!--  <div>{{prifileValidations.consumersPage.mobile_validation.mobile_mix_required_Validations}}</div> -->
                    }
                  </div>}

                  <div class="invalid-feedback">
                    <div
                      *ngIf="consumersControls['mobile_Secondar_Number'].value &&consumersControls['mobile_Primary_Number'].value&&consumersControls['mobile_Secondar_Number'].value == consumersControls['mobile_Primary_Number'].value">
                      <div>{{prifileValidations.ProfilePage.mobile_validation.same_MobileNumber}}</div>
                    </div>
                    <div
                      *ngIf="consumersControls['mobile_Secondar_Number'].value &&consumersControls['mobile_Secondar_Number'].value.length>0 &&(consumersControls['secondaryCountryCode'].value == null ||consumersControls['secondaryCountryCode'].value == undefined)">
                      <div> {{ prifileValidations.consumersPage.countryCode.secoundary_countryCode_required_validations
                        }}</div>
                    </div>

                  </div>
                </div>
              </div>




              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Email Address<span class="required">*</span></label>
                  <input type="email" class="form-control" id="email_first" formControlName="email_first"
                    name="email_first" placeholder="Enter Email Address">
                  @if(submitted && consumersControls['email_first'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['email_first'].errors['required']){
                    <div>{{prifileValidations.ProfilePage.emmail_validation.email_required_validations}}</div>}
                    @if(consumersControls['email_first'].errors['pattern']){
                    <div>{{prifileValidations.ProfilePage.emmail_validation.email_pattern}}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Email Address - Secondary</label>
                  <input type="email" class="form-control" id="email_secound" formControlName="email_secound"
                    name="email_secound" placeholder="Enter Email Address Secondary">
                  @if(submitted && consumersControls['email_secound'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['email_secound'].errors['pattern']){
                    <div>{{prifileValidations.ProfilePage.emmail_validation.email_pattern}}</div>}
                  </div>}
                  <div class="invalid-feedback">
                    <div
                      *ngIf="consumersControls['email_secound'].value&&consumersControls['email_first'].value&&consumersControls['email_secound'].value == consumersControls['email_first'].value">
                      <div>{{prifileValidations.ProfilePage.mobile_validation.same_Email}}</div>
                    </div>

                  </div>

                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Occupied Residential Unit</label>
                  <input type="text" class="form-control" id="Occupied_Residential_Unit"
                    formControlName="Occupied_Residential_Unit" name="Occupied_Residential_Unit"
                    placeholder="Enter Occupied Residential Unit">
                  <!--  @if(submitted && consumersControls['Occupied_Residential_Unit'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['Occupied_Residential_Unit'].errors['required']){
                    <div>{{prifileValidations.consumersPage.Occupied_residential_unit
                      }}</div>}
                  </div>} -->
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">Registered Date</label>
                  <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'" [(value)]="fromDate"
                    formControlName="Registered_date"></kendo-datepicker> -->
                  <input class="form-control" type="date" id="Registered_date" formControlName="Registered_date"
                    name="Registered_date" id="example-date-input">
                  @if(submitted && consumersControls['Registered_date'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['Registered_date'].errors['required']){
                    <div>{{prifileValidations.consumersPage.Registered_date
                      }}</div>}
                  </div>}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="b">C/O Name</label>
                  <input type="text" class="form-control" id="Enter_CO_Name" formControlName="Enter_CO_Name"
                    name="Enter_CO_Name" placeholder="Enter C/O Name">
                  @if(submitted && consumersControls['Enter_CO_Name'].errors){
                  <div class="invalid-feedback">
                    @if(consumersControls['Enter_CO_Name'].errors['required']){
                    <div>{{prifileValidations.consumersPage.co_name.Enter_co_name_required_Validations}}</div>}
                    @if(consumersControls['Enter_CO_Name'].errors['minlength']){
                    <div>{{prifileValidations.consumersPage.co_name.min_required_Validations}}</div>
                    }
                    @if(consumersControls['Enter_CO_Name'].errors['maxlength']){
                    <div>{{prifileValidations.consumersPage.co_name.min_required_Validations}}</div>
                    }
                    @if(consumersControls['Enter_CO_Name'].errors['pattern']){
                    <div>{{prifileValidations.consumersPage.co_name.allow_spaces}}</div>}
                  </div>}
                </div>
              </div>
              <div class=" col-lg-12">
                <div class="row">
                  <div class="col-sm">
                    <div class="form-check">
                      <input [disabled]="true" class="form-check-input" type="checkbox" id="Custom_invoice_address"
                        formControlName="Custom_invoice_address" name="Custom_invoice_address">
                      <label class="form-check-label" for="formCheck1">
                        Custom Invoice address required
                      </label>
                    </div>
                  </div>
                  <!-- <div class="col-sm">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="enable_wallet" formControlName="enable_wallet"
                        name="enable_wallet">
                      <label class="form-check-label" for="formCheck1">
                        Enable Wallet
                      </label>
                        @if(submitted && consumersControls['enable_wallet'].errors){
                      <div class="invalid-feedback">
                        @if(consumersControls['enable_wallet'].errors['required']){
                        <div>{{prifileValidations.consumersPage.enable_wallet}}</div>}
                      </div>} 
                    </div>

                  </div> -->
                </div>
                <!-- <div class="row">
                  <div class="mt-4 mb-4">
                    <div class="form-check">
                      <input [disabled]="true" class="form-check-input" type="checkbox" id="Custom_invoice_address"
                        formControlName="Custom_invoice_address" name="Custom_invoice_address">
                      <label class="form-check-label" for="formCheck1">
                        Custom Invoice address required
                      </label>
                    </div>
                  </div>
                   -->
                <!-- </div> -->
              </div>
            </div>


          </form>
          <hr>
          <p *ngIf="consumersControls['Custom_invoice_address'].value"><b>Rearrange the below Information as
              desired by dragging them.</b> </p>
          @if(consumersControls['Custom_invoice_address'].value == true){
          <kendo-sortable #sortable [navigable]="true" [animation]="true" (dragOver)="onDragOver($event)"
            (navigate)="onNavigate($event)" class="row cursor-move" itemClass="col-lg-3"
            [kendoSortableBinding]="inputFields">
            <ng-template let-inputFields="item">
              <label> {{inputFields.label}} </label>
              <input class="form-control" disabled name="{{inputFields.name}}" reado [(ngModel)]="inputFields.value"
                (keyup)="onFocusOut($event,inputFields.name)">
              <!-- @if(submitted){<span
                class="invalid-feedback">{{inputFields.value==''?getvaliadation(inputFields.value,inputFields.name)
                : ''}}</span>} -->
              @if(inputFields.name=='Block'){<span class="invalid-feedback">{{blockRegexError}}</span>}
              @if(inputFields.name=='Flat_House'){<span class="invalid-feedback">{{Flat_HouseRegex_error}}</span>}
              @if(inputFields.name=='Floor'){ <span class="invalid-feedback">{{FloorRegex_error}}</span>}
              @if(inputFields.name=='Tower'){ <span class="invalid-feedback">{{TowerRegex_error}}</span>}
            </ng-template>
          </kendo-sortable>
          }


          <!-- <div class="col-lg-3">
                            <div class="mb-3">
                              <label for="b">Flat/House#</label>
                              <input type="text" class="form-control" id="" placeholder="Enter Flat/House">
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="mb-3">
                              <label for="b">Floor</label>
                              <input type="text" class="form-control" id="" placeholder="Enter Floor">
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="mb-3">
                              <label for="b">Tower</label>
                              <input type="text" class="form-control" id="" placeholder="Enter Tower">
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="mb-3">
                              <label for="b">Block</label>
                              <input type="text" class="form-control" id="" placeholder="Enter Block">
                            </div>
                          </div> -->




          <ul class="list-inline wizard d-flex justify-content-end mt-3">
            <li class="previous list-inline-item"><button class="btn btn-outline-primary"
                [routerLink]="'/consumers'">Cancel</button></li>
            @if(updated_consumerData?.id) {<li class="next list-inline-item"><button class="btn btn-primary"
                (click)="saveConsumers('Update')">update</button>
            </li>}
            @else { <li class="next list-inline-item"><button class="btn btn-primary"
                (click)="saveConsumers('Create')">Save</button>
            </li>}
          </ul>



        </div>
      </div>
    </div>
  </div>

</div>