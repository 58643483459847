import { setDefaultOptions, deepExtend } from '../common';
import { SankeyElement } from './element';
import { Box, TextBox } from '../core';
const INSIDE = 'inside';
const BEFORE = 'before';
const AFTER = 'after';

export class Label extends SankeyElement {
    getElement() {
        const options = deepExtend({}, this.options, this.options.node.label);
        const { node, totalWidth, position, text, offset } = options;

        if (!options.visible || !text) {
            return null;
        }

        const nodeBox = new Box(node.x0, node.y0, node.x1, node.y1);
        const visualOptions = this.visualOptions();
        const textbox = new TextBox(text, visualOptions);
        textbox.reflow(new Box());
        const textSizeBox = textbox.box;

        const goesOutside = node.x1 + textSizeBox.width() > totalWidth;
        const textY = nodeBox.center().y - (textSizeBox.height() / 2);
        const side = position === BEFORE || (position === INSIDE && goesOutside) ? BEFORE : AFTER;
        const textOrigin = [side === BEFORE ? node.x0 - textSizeBox.width() : node.x1, textY];

        const textRect = new Box(textOrigin[0], textOrigin[1], textOrigin[0] + textSizeBox.width(), textOrigin[1] + textSizeBox.height());
        textRect.translate(offset.left || 0, offset.top || 0);
        textbox.reflow(textRect);

        textbox.renderVisual();

        return textbox.visual;
    }

    visualOptions() {
        const options = deepExtend({}, this.options, this.options.node.label);
        return {
            color: options.color,
            opacity: options.opacity,
            font: options.font,
            border: options.border,
            margin: options.margin,
            padding: options.padding,
            align: options.align,
        };
    }
}

setDefaultOptions(Label, {
    position: INSIDE, // inside, before, after
});

export const resolveLabelOptions = (node, options, totalWidth) => deepExtend({},
    options,
    {
        node,
        totalWidth,
        visual: node.label.visual,
        visible: node.label.visible,
        margin: node.label.margin,
        padding: node.label.padding,
        border: node.label.border,
        align: node.label.align,
        opacity: node.label.opacity,
        offset: node.label.offset
    }
);
