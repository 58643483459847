import { deepExtend } from '../common';

const max = (array, mapFn) => Math.max.apply(null, array.map(mapFn));
const min = (array, mapFn) => Math.min.apply(null, array.map(mapFn));
const sum = (array, mapFn) => array.map(mapFn).reduce((acc, curr) => (acc + curr), 0);
const sortAsc = (a, b) => a.y0 - b.y0;
const sortSource = (a, b) => sortAsc(a.source, b.source);
const sortTarget = (a, b) => sortAsc(a.target, b.target);
const value = (node) => node.value;

function sortLinks(nodes) {
    nodes.forEach((node) => {
        const { sourceLinks, targetLinks } = node;
        sourceLinks.sort(sortTarget);
        targetLinks.sort(sortSource);
    });
}

class Sankey {
    constructor(options) {
        const offset = options.nodesOptions.offset || {};
        this.data = {
            nodes: options.nodes.map((node) => deepExtend({}, { offset }, node)),
            links: options.links.map((link) => deepExtend({}, link))
        };

        this.width = options.width;
        this.height = options.height;
        this.offsetX = options.offsetX || 0;
        this.offsetY = options.offsetY || 0;
        this.nodeWidth = options.nodesOptions.width;
        this.nodePadding = options.nodesOptions.padding;
    }

    calculate() {
        const { nodes, links } = this.data;
        this.connectLinksToNodes(nodes, links);
        this.calculateNodeValues(nodes);
        this.calculateNodeDepths(nodes);

        const columns = this.calculateNodeColumns(nodes);
        this.calculateNodeBreadths(columns);
        this.applyNodesOffset(nodes);
        this.calculateLinkBreadths(nodes);

        return this.data;
    }

    connectLinksToNodes(nodes, links) {
        const nodesMap = new Map();

        nodes.forEach((node) => {
            node.sourceLinks = [];
            node.targetLinks = [];
            node.id = node.id !== undefined ? node.id : node.label.text;
            nodesMap.set(node.id, node);
        });

        links.forEach((link) => {
            link.source = nodesMap.get(link.sourceId);
            link.target = nodesMap.get(link.targetId);
            link.source.sourceLinks.push(link);
            link.target.targetLinks.push(link);
        });
    }

    calculateNodeValues(nodes) {
        nodes.forEach((node) => {
            node.value = Math.max(
                sum(node.sourceLinks, value),
                sum(node.targetLinks, value)
            );
        });
    }

    calculateNodeDepths(nodes) {
        let current = new Set(nodes);
        let next = new Set();
        let currDepth = 0;
        while (current.size) {
            const currentNodes = Array.from(current);
            for (let n = 0; n < currentNodes.length; n++) {
                const node = currentNodes[n];
                node.depth = currDepth;
                for (let l = 0; l < node.sourceLinks.length; l++) {
                    const link = node.sourceLinks[l];
                    next.add(link.target);
                }
            }
            currDepth++;
            current = next;
            next = new Set();
        }
    }

    calculateNodeColumns(nodes) {
        const maxDepth = max(nodes, (d) => d.depth);
        const columnWidth = (this.width - this.offsetX - this.nodeWidth) / maxDepth;
        const columns = new Array(maxDepth + 1);
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            const depth = Math.max(0, Math.min(maxDepth, node.sourceLinks.length ? node.depth : maxDepth));
            node.x0 = this.offsetX + depth * columnWidth;
            node.x1 = node.x0 + this.nodeWidth;
            columns[depth] = columns[depth] || [];
            columns[depth].push(node);
        }

        return columns;
    }

    calculateNodeBreadths(columns) {
        const kSize = min(columns, (c) => (this.height - this.offsetY - (c.length - 1) * this.nodePadding) / sum(c, value));

        for (let c = 0; c < columns.length; c++) {
            const nodes = columns[c];
            let y = this.offsetY;
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                node.y0 = y;
                node.y1 = y + node.value * kSize;
                y = node.y1 + this.nodePadding;
                for (let l = 0; l < node.sourceLinks.length; l++) {
                    const link = node.sourceLinks[l];
                    link.width = link.value * kSize;
                }
            }

            y = (this.height - y + this.nodePadding) / (nodes.length + 1);
            for (let i = 0; i < nodes.length; ++i) {
                const node = nodes[i];
                node.y0 += y * (i + 1);
                node.y1 += y * (i + 1);
            }
        }

        for (let c = 0; c < columns.length; c++) {
            sortLinks(columns[c]);
        }
    }

    applyNodesOffset(nodes) {
        nodes.forEach((node) => {
            const offsetX = (node.offset ? node.offset.left : 0) || 0;
            const offsetY = (node.offset ? node.offset.top : 0) || 0;
            node.x0 += offsetX;
            node.x1 += offsetX;
            node.y0 += offsetY;
            node.y1 += offsetY;
        });
    }

    calculateLinkBreadths(nodes) {
        nodes.forEach((node) => {
            const { sourceLinks, targetLinks } = node;
            let y = node.y0;
            let y1 = y;
            sourceLinks.forEach((link) => {
                link.y0 = y + link.width / 2;
                y += link.width;
            });
            targetLinks.forEach((link) => {
                link.y1 = y1 + link.width / 2;
                y1 += link.width;
            });
        });
    }
}

export const calculateSankey = (options) => new Sankey(options).calculate();
