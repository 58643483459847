<div class="container-fluid">
    <section *ngIf="billList && billList.length>0">
        <div class="row">
            <div class="col-lg-12">
                <accordion [closeOthers]="false" class="accordion custom-accordian-bill" [isAnimated]="true">
                    @if(billGenerationType =='Combined'){
                    <div *ngFor="let bills of billList; let i = index">
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item5>
                            <div accordion-heading class="d-flex flex-grow-1">
                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                            <span class="text-primary font-size-16">Bill Details ({{bills?.utility}})
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mx-2 mt-3 text-accordian">
                                        <div class="col-lg-2  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Previous Bill Date </label>
                                                <p>{{bills?.lastBillGeneratedDate!=null?
                                                    (bills?.lastBillGeneratedDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="mb-3">
                                                <label class="form-label">Current Bill Date</label>
                                                <p>{{bills?.billGeneratedDate!=null?
                                                    (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="mb-3">
                                                <label class="form-label">Due Date</label>
                                                <p>{{bills.dueDate!=null?
                                                    (bills.dueDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-2 ">
                                            <div class="mb-3">
                                                <label class="form-label">Subtotal</label>
                                                <p>
                                                    {{bills?.billAmount!=undefined?(bills.billAmount
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 justify-content-end d-flex">
                                            <div class="mb-3">
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="billsDataById?.paymentStatus !=='Paid' && bills?.isInvalidBill==false"
                                                    (click)="invalidBillsModal(invalidBillModal,$event)"><i
                                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark As
                                                    Invalid Bill
                                                </button>
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="billsDataById?.paymentStatus !=='Paid' &&  bills?.isInvalidBill==false"
                                                    (click)="markASPaid3(markASPaid,bills.totalAmount)"><i
                                                        class="bx bx-file font-size-16 align-middle"></i> Mark As
                                                    Paid
                                                </button>
                                                <button type="button" class="btn btn-primary "
                                                    (click)="ondownloadePdf($event)" title="Download Bill">
                                                    <i class="bx bx-download font-size-16 align-middle "></i>
                                                </button>&nbsp;
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="billsDataById?.paymentStatus =='Paid'"
                                                    (click)="dondownloadeReceipt($event)" title="Download receipt">
                                                    <i class="bx bx-download font-size-16 align-middle "></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4 justify-content-end d-flex" *ngIf="role =='Consumer'">
                                            <button type="button" class="btn btn-primary h-37"
                                                (click)="ondownloadePdf($event)">
                                                <i class="bx bx-download  align-middle"></i>
                                            </button>
                                        </div> -->
                                        <!-- <div class="col-lg-2 ">
                                            <div class="mb-3">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="ondownloadePdf($event)"
                                                    [disabled]="bills?.isInvalidBill==true">
                                                    <i class="bx bx-download font-size-16 align-middle me-2"></i>
                                                    Download
                                                </button>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-16">Consumer
                                            Details</p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                            <!-- gas -->

                            <div class="row" *ngIf="bills?.gas.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/gas.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Gas Details : (Meter No :{{
                                        bills.gas[0].deviceSrno
                                        }})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.gas.length>0">
                                <div class="row" *ngFor="let gas of bills?.gas">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.previousReadingDate!=null?
                                                            (gas?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.currentReadingDate!=null?
                                                            (gas?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousMeterReading!=undefined?gas.previousMeterReading
                                                            +' m³' :'-'}}

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentMeterReading!=undefined?gas.currentMeterReading
                                                            +' m³':'-'}}

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Units Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.noofUnitsConsumed?gas.noofUnitsConsumed +'
                                                            m³':'-'}}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0"> {{ gas?.unitRate != null ?
                                                            (gas.unitRate | currency: 'INR' : 'symbol' : '1.2-2') :
                                                            '₹0.00' }} </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"> Amount</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0"> {{ gas?.billAmount != null ?
                                                            (gas.billAmount | currency: 'INR' : 'symbol' : '1.2-2')
                                                            :
                                                            '₹0.00' }} </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"></label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="row" *ngIf="gas?.otherCharges?.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody class="align-items-center">
                                                <tr *ngFor="let charge of gas.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.amount!=undefined?(charge.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td class="font-weight-bolder">
                                                        {{charge.gstAmt!=undefined?(charge.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.total!=undefined?(charge.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder"> Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{gas.totalServiceCharge!=undefined?(gas.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{gas.totalAmount!=undefined?(gas.totalAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- water -->
                            <div class="row" *ngIf="bills?.water.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/water.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Water Details : (Meter No :{{
                                        bills?.water[0]?.deviceSrno
                                        }})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.water.length>0">
                                <div class="row" *ngFor="let water of bills?.water">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{water?.previousReadingDate!=null?
                                                            (water?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{water?.currentReadingDate!=null?
                                                            (water?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.previousMeterReading!=undefined?water.previousMeterReading
                                                            +' L' :'-'}}

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentMeterReading!=undefined?water.currentMeterReading
                                                            +' L':'-'}}

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Units Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water
                                                            ?.noofUnitsConsumed!=undefined?water.noofUnitsConsumed+'
                                                            L':'-'}}</p>

                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center mb-1 ">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.unitRate!=undefined?(water.unitRate
                                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"> Amount</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.billAmount!=undefined?(water.billAmount
                                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"></label>
                                                    </div>
                                                    <div class="flex-grow-1">


                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="row" *ngIf="water?.otherCharges.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of water?.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.amount!=undefined?(charge.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td class="font-weight-bolder">
                                                        {{charge.gstAmt!=undefined?(charge.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.total!=undefined?(charge.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder"> Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{water.totalServiceCharge!=undefined?(water.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{water?.totalAmount!=undefined?(water.totalAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- electricity -->
                            <div class="row" *ngIf="bills?.electricity.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/electricity.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Electricity Details : (Meter No :
                                        {{bills?.electricity[0]?.deviceSrno
                                        }})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.electricity.length>0">
                                <div *ngFor="let ele of bills?.electricity">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" pt-2 ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingEB!=undefined?ele.electricityPreviousMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading
                                                                DG</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingDG
                                                                !=undefined?ele.electricityPreviousMeterReadingDG +'
                                                                kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingEB!=undefined?ele.electricityCurrentMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingDG!=undefined?ele.electricityCurrentMeterReadingDG
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">

                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No. Of Units Consumed EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedEB!=undefined?ele.noofElectricityUnitsConsumedEB+'
                                                                kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedDG!=undefined?ele.noofElectricityUnitsConsumedDG
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateEB!=undefined?(ele.unitRateEB
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>

                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateDG!=undefined?(ele.unitRateDG
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Reading Date
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.previousReadingDate!=null?
                                                                (ele?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                                uppercase)
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Reading Date </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.currentReadingDate!=null?
                                                                (ele?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                                uppercase)
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label"> Amount</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="ele?.otherCharges.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of ele?.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.amount!=undefined?(charge.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td class="font-weight-bolder">
                                                        {{charge.gstAmt!=undefined?(charge.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.total!=undefined?(charge.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>


                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder"> Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{ele.totalServiceCharge!=undefined?(ele.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{ele?.totalAmount!=undefined?(ele.totalAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- other Charges -->
                            <div class="row" *ngIf="bills.generalCharges.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/rupee.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Other Charges</span>
                                </div>
                            </div>
                            <div>
                                <div class="row" *ngIf="bills.generalCharges.length>0">
                                    <table class="table custom-border-table text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Service Name</th>
                                                <th scope="col">Amount(₹)</th>
                                                <th scope="col">GST (%)</th>
                                                <th scope="col">GST Amount(₹)</th>
                                                <th scope="col">Total(₹)</th>
                                            </tr>
                                        </thead>
                                        <tbody class="align-items-center">
                                            <tr *ngFor="let charge of bills.generalCharges">
                                                <td>{{charge.name}}</td>
                                                <td class="font-weight-bolder">
                                                    {{charge.amount!=undefined?(charge.amount
                                                    | number: '1.2-2') : '₹0.00'}}</td>
                                                <td>{{charge.gst}}</td>

                                                <td class="font-weight-bolder">
                                                    {{charge.gstAmt!=undefined?(charge.gstAmt
                                                    | number: '1.2-2') : '₹0.00'}}</td>
                                                <td class="font-weight-bolder">
                                                    {{charge.total!=undefined?(charge.total
                                                    | number: '1.2-2') : '₹0.00'}}</td>

                                            </tr>
                                            <tr>
                                                <th scope="row"></th>
                                                <td colspan="1"></td>
                                                <td></td>
                                                <td class="font-weight-bolder"> Amount</td>
                                                <td class="font-weight-bolder">
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    | number: '1.2-2') : '₹0.00'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 ">
                                    <div class="d-flex justify-content-around penaltyBill">
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                            <!-- <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmountwithoutpenalty!=undefined?(bills.totalAmountwithoutpenalty
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p> -->
                                        </div>
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalPenalty!=undefined?(bills?.totalPenalty
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                            <p class="font-size-18 font-weight-bold"> {{ ((bills?.totalAmount ?? 0)
                                                + (bills?.totalPenalty ?? 0)) | currency:
                                                'INR':true:'1.2-2' }}</p>
                                            <!-- <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmount!=undefined?(bills.totalAmount+bills?.totalPenalty
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </accordion-group>
                    </div>
                    }@else {
                    <!-- individual Bills  -->

                    <div *ngFor="let bills of billList; let i = index">
                        <!-- Gas -->

                        @if(bills?.gas.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item2>

                            <div accordion-heading class="d-flex flex-grow-1">

                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/water.png">
                                            <span class="text-primary font-size-15">
                                                Gas Details :{{ bills.utility }}
                                            </span>
                                        </div>

                                    </div>

                                    <div class="row mx-2 mt-3 text-accordian">
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Previous Bill Date</label>
                                                <p>{{bills?.lastBillGeneratedDate!=null?
                                                    (bills?.lastBillGeneratedDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label"> Current Bill Date</label>
                                                <p>{{bills?.billGeneratedDate!=null?
                                                    (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Due Date</label>
                                                <p>{{bills?.dueDate!=null?
                                                    (bills?.dueDate | date:'d MMMM yyyy')
                                                    :'' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Subtotal</label>
                                                <p>
                                                    {{bills?.billAmount!=undefined?(bills.billAmount
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 justify-content-end d-flex" *ngIf="role =='Admin'">
                                            <div class="mb-3">
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="bills?.isInvalidBill==true"
                                                    (click)="invalidBillsModal(invalidBillModal,$event)"><i
                                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark
                                                    As
                                                    Invalid Bill
                                                </button>
                                                <button type="button" class="btn btn-primary me-2"
                                                    [disabled]="billsDataById?.paymentStatus=='paid'"
                                                    (click)="markASPaid3(markASPaid,bills.totalAmount)"><i
                                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark
                                                    As
                                                    Paid
                                                </button>
                                                <button type="button" class="btn btn-primary "
                                                    (click)="ondownloadePdf($event)" *ngIf="bills?.isInvalidBill==true">
                                                    <i class="bx bx-download font-size-16 align-middle me-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer
                                            Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="row" *ngIf="bills?.gas.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/gas.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Gas Details : (Meter No :{{
                                        bills.gas[0].deviceSrno
                                        }})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.gas.length>0">
                                <div class="row" *ngFor="let gas of bills?.gas">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.previousReadingDate!=null?
                                                            (gas?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.currentReadingDate!=null?
                                                            (gas?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousMeterReading !==undefined
                                                            ?gas.previousMeterReading
                                                            +' m³'
                                                            :'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentMeterReading
                                                            !=undefined?gas.currentMeterReading +' m³':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 "></div>
                                            <div class=" ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Units Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.noofUnitsConsumed!=undefined?gas.noofUnitsConsumed +'
                                                            m³':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.unitRate!=undefined?(gas.unitRate
                                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"> Amount</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0"> {{ gas?.billAmount != null ?
                                                            (gas.billAmount | currency: 'INR' : 'symbol' : '1.2-2')
                                                            :
                                                            '₹0.00' }} </p>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    <div class="row" *ngIf="gas.otherCharges.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of gas.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.amount!=undefined?(charge.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td class="font-weight-bolder">
                                                        {{charge.gstAmt!=undefined?(charge.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{charge.total!=undefined?(charge.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalServiceCharge!=undefined?(bills.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{gas.totalAmount!=undefined?(gas.totalAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="d-flex justify-content-around penaltyBill">
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                        <p class="font-size-18 font-weight-bold">
                                            {{bills?.totalAmount!=undefined?(bills.totalAmount
                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                    </div>
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                        <p class="font-size-18 font-weight-bold">
                                            {{bills?.totalPenalty!=undefined?(bills?.totalPenalty
                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                    </div>
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                        <p class="font-size-18 font-weight-bold"> {{ ((bills?.totalAmount ?? 0)
                                            + (bills?.totalPenalty ?? 0)) | currency:
                                            'INR':true:'1.2-2' }}</p>
                                    </div>
                                </div>
                            </div>

                        </accordion-group>
                        }

                        <!--  Water bill  -->

                        @if(bills?.water.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item2>

                            <div accordion-heading class="d-flex flex-grow-1">

                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/water.png">
                                            <span class="text-primary font-size-15">
                                                Water Details : {{ bills.utility}}
                                            </span>
                                        </div>

                                    </div>

                                    <div class="row mx-2 mt-3 text-accordian">
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Bill Date</label>
                                                <p>{{bills?.billGeneratedDate!=null?
                                                    (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                    :'' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label"> Current Bill Date</label>
                                                <p>{{bills?.billGeneratedDate!=null?
                                                    (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Due Date</label>
                                                <p>{{bills?.dueDate!=null?
                                                    (bills?.dueDate | date:'d MMMM yyyy')
                                                    :'' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Subtotal</label>
                                                <p>
                                                    {{bills?.billAmount!=undefined?(bills.billAmount
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 justify-content-end d-flex" *ngIf="role =='Admin'">
                                            <div class="mb-3">
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="bills?.isInvalidBill==false"
                                                    (click)=" invalidBillsModal(invalidBillModal,$event)"><i
                                                        class="bx bx-file font-size-16 align-middle me-2"></i> Mark
                                                    As
                                                    Invalid Bill
                                                </button>
                                                <button type="button" class="btn btn-primary me-2"
                                                    *ngIf="billsDataById?.paymentStatus =='Unpaid' ||  bills?.isInvalidBill==false"
                                                    (click)="markASPaid3(markASPaid,bills.totalAmount)"><i
                                                        class="bx bx-file font-size-16 align-middle"></i> Mark As
                                                    Paid
                                                </button>
                                                <button type="button" class="btn btn-primary "
                                                    (click)="ondownloadePdf($event)" *ngIf="bills?.isInvalidBill==true">
                                                    <i class="bx bx-download font-size-16 align-middle me-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="row" *ngIf="bills?.gas.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/gas.png">
                                    <span class="font-weight-bold ms-3 font-size-15">water Details : (Meter No :{{
                                        bills.water[0].deviceSrno
                                        }})</span>
                                </div>
                            </div>


                            <div *ngIf="bills?.water.length>0">
                                <div class="row" *ngFor="let water of bills?.water">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end">{{water?.previousReadingDate!=null?
                                                            (water?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{water?.currentReadingDate!=null?
                                                            (water?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                            uppercase)
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.previousMeterReading !==undefined ?
                                                            water.previousMeterReading
                                                            +' L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentMeterReading!=undefined?water.currentMeterReading
                                                            +' L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                            </div>
                                            <div class=" ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.noofUnitsConsumed!=undefined?water.noofUnitsConsumed:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.unitRate!=undefined?(water.unitRate
                                                            | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label"> Amount</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0"> {{ water?.billAmount != null ?
                                                            (water.billAmount | currency: 'INR' : 'symbol' : '1.2-2')
                                                            :
                                                            '₹0.00' }} </p>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="row" *ngIf="water.otherCharges.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of water.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.amount!=undefined?(bills.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td class="font-weight-bolder">
                                                        {{bills.gstAmt!=undefined?(bills.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.total!=undefined?(bills.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalServiceCharge!=undefined?(bills.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{water?.billAmount!=undefined?(water.billAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                    </div>


                                    <div class="col-md-12 ">
                                        <div class="d-flex justify-content-around penaltyBill">
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.water.totalPenalty!=undefined?(bills?.totalPenalty
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold"> {{ ((bills?.totalAmount ?? 0)
                                                    + (bills?.totalPenalty ?? 0)) | currency:
                                                    'INR':true:'1.2-2' }}</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </accordion-group>

                        }
                        <!--  Electricity bill  -->
                        @if(bills?.electricity.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item3>
                            <div accordion-heading class="d-flex flex-grow-1">
                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img"
                                                src="../../../../assets/images/brands/electricity.png">
                                            <span class="text-primary font-size-15">Electricity : Billing Details
                                                ({{bills?.utility}})
                                            </span>
                                        </div>

                                    </div>
                                    <div *ngFor="let ele of bills?.electricity">
                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Current Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'-' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{bills.electricity[0]?.dueDate!=null?
                                                        (bills.electricity[0]?.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>
                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 justify-content-end d-flex" *ngIf="role =='Admin'">
                                                <div class="mb-3">
                                                    <button type="button" class="btn btn-primary me-2"
                                                        *ngIf="bills?.isInvalidBill==true"
                                                        (click)=" invalidBillsModal(invalidBillModal,$event)"><i
                                                            class="bx bx-file font-size-16 align-middle me-2"></i> Mark
                                                        As
                                                        Invalid Bill
                                                    </button>
                                                    <button type="button" class="btn btn-primary me-2"
                                                        [disabled]="billsDataById?.paymentStatus=='paid'"
                                                        (click)="markASPaid3(markASPaid,bills.totalAmount)"><i
                                                            class="bx bx-file font-size-16 align-middle me-2"></i> Mark
                                                        As
                                                        Paid
                                                    </button>
                                                    <button type="button" class="btn btn-primary "
                                                        (click)="ondownloadePdf($event)"
                                                        *ngIf="bills?.isInvalidBill==true">
                                                        <i class="bx bx-download font-size-16 align-middle me-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>

                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="row" *ngIf="bills?.gas.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/gas.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Electricity Details : (Meter No :{{
                                        bills.electricity[0].deviceSrno
                                        }})</span>
                                </div>
                            </div>


                            <div *ngIf="bills?.electricity.length>0">
                                <div *ngFor="let ele of bills?.electricity let i=index">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" pt-2 ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingEB!=undefined?ele.electricityPreviousMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading
                                                                DG</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingDG
                                                                !=undefined?ele.electricityPreviousMeterReadingDG +'
                                                                kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingEB!=undefined?ele.electricityCurrentMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingDG!=undefined?ele.electricityCurrentMeterReadingDG
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No.Of Units Consumed EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedEB!=undefined?ele.noofElectricityUnitsConsumedEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedDG!=undefined?ele.noofElectricityUnitsConsumedDG
                                                                +'kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateEB!=undefined?(ele.unitRateEB
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                                            <!--  <p class="float-end">{{ele?.unitRateEB?ele.unitRateEB:'-'}}</p> -->
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateDG!=undefined?(ele.unitRateDG
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Reading Date</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.previousReadingDate!=null?
                                                                (ele?.previousReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                                uppercase)
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Reading Date </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.currentReadingDate!=null?
                                                                (ele?.currentReadingDate | date: 'd MMMM yyyy h:mm a' |
                                                                uppercase)
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label"> Amount</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="ele.otherCharges.length>0">
                                        <table class="table custom-border-table text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount(₹)</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount(₹)</th>
                                                    <th scope="col">Total(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of ele.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>
                                                        {{charge.amount!=undefined?(charge.amount
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.gst}}</td>

                                                    <td>
                                                        {{charge.gstAmt!=undefined?(charge.gstAmt
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                    <td>{{charge.total}}</td>
                                                    <td>
                                                        {{charge.total!=undefined?(charge.total
                                                        | number: '1.2-2') : '₹0.00'}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder"> Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalServiceCharge!=undefined?(bills.totalServiceCharge
                                                        | number: '1.2-2') : '₹0.00'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                        </div>
                                    </div>

                                    <div class="col-md-12 ">
                                        <div class="d-flex justify-content-around penaltyBill">
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmount!=undefined?(bills?.totalAmount
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalPenalty!=undefined?(bills?.totalPenalty
                                                    | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold"> {{ ((bills?.totalAmount ?? 0)
                                                    + (bills?.totalPenalty ?? 0)) | currency:
                                                    'INR':true:'1.2-2' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </accordion-group>
                        }
                        <!-- other charges -->
                        @if(bills?.isOtherBill){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item7>


                            <div accordion-heading class="d-flex flex-grow-1">


                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img"
                                                src="../../../../assets/images/brands/electricity.png">
                                            <span class="text-primary font-size-15">Other Charges : Billing Details
                                                ({{bills?.billNo}})
                                            </span>
                                        </div>

                                    </div>
                                    <div>

                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{bills.dueDate!=null?
                                                        (bills.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>


                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>
                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item7?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>

                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="row custom-list-bill-view p-2 rounded mt-2">
                                <div class="col-lg-12">
                                    <div class="">
                                        <div class="row">
                                            <table class="table custom-border-table text-center">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Service Name</th>
                                                        <th scope="col">Amount(₹)</th>
                                                        <th scope="col">GST (%)</th>
                                                        <th scope="col">GST Amount(₹)</th>
                                                        <th scope="col">Total(₹)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let charge of bills?.generalCharges">
                                                        <td>{{charge.name}}</td>
                                                        <td>
                                                            {{charge.amount!=undefined?(charge.amount
                                                            | number: '1.2-2') : '₹0.00'}}</td>
                                                        <td>{{charge.gst}}</td>

                                                        <td>
                                                            {{charge.gstAmt!=undefined?(charge.gstAmt
                                                            | number: '1.2-2') : '₹0.00'}}</td>
                                                        <td>{{charge.total}}</td>
                                                        <td>
                                                            {{charge.total!=undefined?(charge.total
                                                            | number: '1.2-2') : '₹0.00'}}</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td colspan="1"></td>
                                                        <td></td>
                                                        <td class="font-weight-bolder">Total Amount</td>
                                                        <td class="font-weight-bolder">
                                                            {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                            | number: '1.2-2') : '₹0.00'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            <div class="col-md-12">
                                                <div class="text-center totalBill ">
                                                    <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="d-flex justify-content-around penaltyBill">
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.gas[0]?.penaltyAmount!=undefined?(bills?.gas[0]?.penaltyAmount
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}} </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                        | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </accordion-group>
                        }
                    </div>

                    }
                    <!-- Bill Generate -->
                    <!--  <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item3>

                        <div accordion-heading class="d-flex flex-grow-1">

                            <div class="flex-grow-1 custom-bg-body pt-2 ">
                                <div class="d-flex flex-grow-1 align-items-center ms-3">
                                    <div class="flex-grow-1">
                                        <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                        <span class="text-primary font-size-16"> Previous bill dates </span>
                                    </div>

                                </div>



                            </div>
                            <div class="d-flex align-items-center">
                                <i class="mdi font-size-24"
                                    [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                            </div>
                        </div>
                        <div class="row custom-list-bill-view p-2 rounded mt-2">
                            <div class="col-lg-12">

                                <div class="row">
                                    <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Previous Month Bill Generated Date</label>
                                            <p>{{paymentDetails?.lastBillGeneratedDate!=null?
                                                (paymentDetails.lastBillGeneratedDate |date)
                                                :'-' }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Current Month Bill Generated Date </label>
                                            <p>{{paymentDetails?.billGeneratedDate!=null?
                                                (paymentDetails.billGeneratedDate |date)
                                                :'-' }}
                                            </p>

                                        </div>
                                    </div>

                                      <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Billed Dates</label>
                                            <p>{{paymentDetails?.billDays?paymentDetails.billDays:'-'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </accordion-group> -->

                    <!-- paymnet Deatils  -->
                    <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item6>

                        <div accordion-heading class="d-flex flex-grow-1">

                            <div class="flex-grow-1 custom-bg-body pt-2 ">
                                <div class="d-flex flex-grow-1 align-items-center ms-3">
                                    <div class="flex-grow-1">
                                        <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                        <span class="text-primary font-size-16">Payment Information</span>
                                    </div>

                                </div>



                            </div>
                            <div class="d-flex align-items-center">
                                <i class="mdi font-size-24"
                                    [ngClass]="!item6?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </div>
                        </div>
                        <div class="row custom-list-bill-view p-2 rounded mt-2">
                            <div class="col-lg-12">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Mode of Payment</label>
                                                <p>{{paymentDetails?.modeOfPayment?paymentDetails.modeOfPayment:'-'}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Paid through </label>
                                                <p>{{paymentDetails?.paymentType?paymentDetails.paymentType:'-'}}
                                                </p>

                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment link </label>
                                                <p>
                                                    <a [href]="paymentDetails?.paymentLink || ''"
                                                        [attr.target]="paymentDetails?.paymentLink ? '_blank' : null">
                                                        {{ paymentDetails?.paymentLink ? paymentDetails.paymentLink :
                                                        '-' }}
                                                    </a>
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment ID </label>
                                                <p>{{paymentDetails?.paymentId?paymentDetails.paymentId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Order ID</label>
                                                <p>{{paymentDetails?.orderId?paymentDetails.orderId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment date</label>
                                                <p>{{paymentDetails?.paymentDate!=null?
                                                    (paymentDetails.paymentDate | date: 'd MMMM yyyy h:mm a')
                                                    :'-' }}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Settlement ID </label>
                                                <p>{{paymentDetails?.settlementId?paymentDetails.settlementId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Settlement date</label>
                                                <p>{{paymentDetails?.settlementDate!=null?
                                                    (paymentDetails.settlementDate | date: 'd MMMM yyyy h:mm a')
                                                    :'-' }}
                                                </p>

                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment Status</label>
                                                <p>{{paymentDetails?.paymentStatus?paymentDetails.paymentStatus:'-'}}
                                                </p>

                                            </div>
                                        </div>

                                        <!--  <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Total Amount Paid</label>
                                                <p> {{paymentDetails?.totalAmount!=undefined?(paymentDetails.totalAmount
                                                   | currency: 'INR' : 'symbol' : '1.2-2') : '₹0.00'}}
                                                </p>

                                            </div>
                                        </div> -->

                                    </div>

                                </form>
                            </div>
                        </div>


                    </accordion-group>


                </accordion>

            </div>


        </div>
    </section>
    <div class="card-body">
        @if(billList && billList.length<1){ <div class="text-center">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="20" class="auth-logo-dark mx-auto">
                        <img src="assets/images/logo-light.png" alt="" height="20" class="auth-logo-light mx-auto"> -->
            <!-- <img src="../../../assets/images/nodata.jpg" alt="" height="250"> -->
            <h4 class="mt-5">No Data Found</h4>
    </div>
    }

    <!-- Invalid Bill modal -->
    <ng-template #invalidBillModal>

        <div class="">
            <div class="">
                <div class="modal-header ">
                    <h5 class="modal-title">Invalid Bill</h5>
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                        (click)="modalRefInvoice?.hide()"></button>
                </div>
                <div class="modal-body">

                    <div class="mb-3">
                        <label> Reason for invalid bill</label>
                        <textarea [placeholder]="'Enter your reason here'" [(ngModel)]="reason" class="form-control"
                            rows="5"></textarea>
                        <span *ngIf="!reason"></span>
                    </div>

                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveInvlaidBills()"
                        [disabled]="!reason">Submit</button>
                </div>
            </div><!-- /.modal -->
        </div>
    </ng-template>
    <!-- Invalid Bill modal End  -->

    <!-- mark As Paid -->
    <ng-template #markASPaid>

        <div class="modal-header ">
            <h5 class="modal-title">Bill Payment </h5>
            <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                (click)="billapidmodal?.hide()"></button>
        </div>
        <div class="modal-body">
            <form class="row" [formGroup]="paybycashform">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>Mode of Payment </label>
                        <input class="form-control" value="cash" type="text" readonly>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>Amount </label>
                        <input class="form-control" formControlName="amount" type="text" readonly>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>Reason </label>
                        <textarea [placeholder]="'Enter your reason here'" formControlName="reason" class="form-control"
                            rows="5"></textarea>
                        <!--  <input class="form-control" formControlName="reason" [value]="viewBilldeatils.totalAmount"
                        type="text"> -->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>Bill Payment date </label>
                        <input type="text" class="form-control date" formControlName="billPaymentDate"
                            placeholder="Select Date" name="singleDate" autocomplete="off" bsDatepicker [bsConfig]="{
                        isAnimated: true, 
                        dateInputFormat: 'DD/MM/YYYY',
                        maxDate: today,  
                        minDate: minDate,
                        showWeekNumbers: false,
                             
                }" />
                        <!--  <input class="form-control" (change)="billPaymentDateChange($event)" type="date"
                            [attr.min]="minDate" [attr.max]="today"
                            [value]="paybycashform.get('amount')?.value | date:'dd/MM/yyyy'"
                            formControlName="billPaymentDate"> -->
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
            <button type="button" class="btn btn-primary waves-effect waves-light" (click)="payByCash()"
                [disabled]="!paybycashform.valid">Submit</button>
        </div>


    </ng-template>