import { Component, ViewChild } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State } from '@progress/kendo-data-query';
import { billsData } from './bills-data';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { SharedService } from '../../../shared/shared.service';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { LoaderService } from '../../../core/services/loader.service';
import {
  getAdhoctExportResponse,
  getAdhoctResponse,
  getBillsExportResponse,
  getBillsResponse,
  getManualExportResponse,
  getManualResponse,
} from '../../../store/Bills/bills-selector';
import {
  getAdhoclist,
  getAdhoclistExport,
  getBillsListExport,
  getBillslist,
  getManuallist,
  getManuallistExport,
  resetbillsAdhocState,
  resetbillsManualState,
  resetbillsState,
} from '../../../store/Bills/bills.action';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';
import { BillsService } from '../../../core/services/bills.service';

@Component({
  selector: 'app-rp-bills',
  templateUrl: './rp-bills.component.html',
  styleUrl: './rp-bills.component.scss',
})
export class RpBillsComponent {
  orderByQuery: string = '';
  adhocqueryString: string = '';
  manualqueryString: string = '';
  billqueryString: string = '';



  billsearchedValue = '';
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  selectedIndex = 2
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public mySelection: string[] = [];
  public selectableSettings: SelectableSettings;
  public selectableSettingsManual!: SelectableSettings;
  public selectableSettingsAdhoc!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild('BillspdfGrid') public BillspdfGrid: GridComponent | undefined;
  // /
  @ViewChild('manualpdfGrid') public manualpdfGrid: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  public pageSize = 10;
  public manualpageSize = 10;
  public adhocpageSize = 10;
  public skip = 0;
  currentPage: number = 1;
  public billstgridData: GridDataResult = { data: [], total: 0 };
  public manualBilsgridData: GridDataResult = { data: [], total: 0 };
  public adhocBillsgridData: GridDataResult = { data: [], total: 0 };
  billsList: any[] = [];
  manualBillsList: any[] = [];
  adhocBillsList: any[] = [];

  public bill: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public manualbill: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public adhocbill: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  billstotalUserCount: number = 0;
  adhocbillstotalUserCount: number = 0;
  manualBillstotalUserCount: number = 0;
  billGenType?: string;

  billscurrentPage: number = 1;
  maulabillscurrentPage: number = 1;
  adhocbillscurrentPage: number = 1;
  currentUserData: any;
  projectId: any;
  userId: any;

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  //public billpageSizes = true;
  public billpreviousNext = true;
  public billpageSizes = table_pageSize.pageSizes;

  //public manulaBillspageSizes = true;
  public manulaBillspageSizes = table_pageSize.pageSizes;
  public manulaBillspreviousNext = true;
  public adhocbillpageSizes = table_pageSize.pageSizes;
  //public adhocbillpageSizes = true;
  public adhocbillpreviousNext = true;

  public gridData: unknown[] = billsData;
  public gridView!: unknown[];

  manualsarchedValue = '';
  adhocSearchedValue = '';
  billStatus: any;
  breadCrumbItems: Array<{}> | undefined;
  public gridDataAdhoc: unknown[] = billsData;


  constructor(
    private modalService: BsModalService,
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private billsService: BillsService
  ) {
    this.breadCrumbItems = [{ label: 'Bills' }];
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
    }
    if (this.currentUserData.currentUserRoleDetails !== null) {
      this.userId = this.currentUserData.currentUserRoleDetails.userId
    }

    if (history.state.billStatus != undefined && history.state.billStatus !== null) {
      this.billStatus = history.state.billStatus;
    }

    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.selectableSettingsManual = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.selectableSettingsAdhoc = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.getBillsStoreInfo();
  }
  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use

    }
  }
  isFilter: any
  ngOnInit(): void {
    this.isFilter = localStorage.getItem('filter');
    this.gridView = this.gridData;

    if (this.billStatus) {
      // Set up the filtering object
      const status = this.billStatus === 'Paid Bills' ? 'Paid' : 'Unpaid';

      // Prepare the filter for the grid
      this.bill = {
        skip: 0,
        take: 10,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'paymentStatus',
              operator: 'contains',
              value: status,
            },
          ],
        },
      };

      // Dispatch actions to fetch the filtered bills
      this.getAdhoclist();
      this.store.dispatch(resetbillsState());

      // Construct the query string
      this.billqueryString = `$filter=contains(paymentStatus,'${status}')`;

      // Dispatch the action to get the bills list
      this.store.dispatch(
        getBillslist({
          projectId: this.projectId,
          pageIndex: 1,
          pageSize: this.pageSize,
          searchTerm: this.billsearchedValue,
          billType: 'bills',
          filter: this.billqueryString,
          orderBy: this.orderByQuery,
        })
      );

      // Call to get manual list
      this.getMaunualList();
    } else {
      if (this.isFilter == 'true') {
        this.previouesfilter()
      } else {
        this.getAdhoclist();
        this.getBillsList();
        this.getMaunualList();
      }

    }
  }

  public billValueChange(ev: string): void {
    if (ev.length == 0) {
      this.getBillsList();
    }
  }


  public manualValueChange(ev: string): void {
    if (ev.length == 0) {
      this.getMaunualList();
    }
  }


  public adhocValueChange(ev: string): void {
    if (ev.length == 0) {
      this.getAdhoclist();
    }
  }


  previouesfilter() {
    this.filter = this.sharedService.getFilter();
    // this.consumersState.filter = this.filter;

    //  this.queryString = this.sharedService.createQuery(this.consumersState);

    const savedStepIndex = localStorage.getItem('selectedTabIndex');

    console.log(savedStepIndex)
    switch (savedStepIndex) {
      case '0':
        this.bill.filter = this.filter;
        this.billqueryString = this.sharedService.createQuery(this.bill);
        break;
      case '1':
        this.manualbill.filter = this.filter;
        this.manualqueryString = this.sharedService.createQuery(this.manualbill);
        break;

      case '2':
        this.adhocbill.filter = this.filter;
        this.adhocqueryString = this.sharedService.createQuery(this.adhocbill);
        break;
    }
    this.getBillsList();
    this.getMaunualList();
    this.getAdhoclist();




  }



  getAdhoclist() {
    this.store.dispatch(
      getAdhoclist({
        projectId: this.projectId,
        pageIndex: this.adhocbillscurrentPage,
        pageSize: this.adhocpageSize,
        searchTerm: this.adhocSearchedValue,
        billType: 'adhoc',
        filter: this.adhocqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  getAdhoclistExport() {
    this.store.dispatch(
      getAdhoclistExport({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        billType: 'adhoc',
        filter: '',
        orderBy: '',
        criteria: 'All',
      })
    );
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  getBillsListExport() {
    this.store.dispatch(
      getBillsListExport({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        billType: 'bills',
        filter: '',
        orderBy: '',
        criteria: 'All',
      })
    );
  }
  getBillsList() {
    this.store.dispatch(
      getBillslist({
        projectId: this.projectId,
        pageIndex: this.billscurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.billsearchedValue,
        billType: 'bills',
        filter: this.billqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }
  getMaunualListExport() {
    this.store.dispatch(
      getManuallistExport({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        billType: 'manual',
        filter: '',
        orderBy: '',
        criteria: 'All',
      })
    );
  }
  getMaunualList() {
    this.store.dispatch(
      getManuallist({
        projectId: this.projectId,
        pageIndex: this.maulabillscurrentPage,
        pageSize: this.manualpageSize,
        searchTerm: this.manualsarchedValue,
        billType: 'manual',
        filter: this.manualqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  tabChange(event: any): void {
    //console.log(event);
  }

  exportAllAdhoc() {
    this.getAdhoclistExport();
  }

  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.adhocbillselectedRowdata.length == 0) {
       this.getAdhoclistExport();
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }
  exportAllBills() {
    this.getBillsListExport();
  }
  public onBillsExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.BillspdfGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.BillspdfGrid?.saveAsPDF();
      }, 1000);
    }
    /* if (this.adhocbillselectedRowdata.length == 0) {
      this.getBillsListExport();
    } else {
      if (type === 'Excel') {
        setTimeout(() => {
          this.BillspdfGrid?.saveAsExcel();
        }, 1000);
      } else if (type === 'Pdf') {
        setTimeout(() => {
          this.BillspdfGrid?.saveAsPDF();
        }, 1000);
      }
    } */
  }
  exportAllManual() {
    this.getMaunualListExport();
  }
  public onManualExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.manualpdfGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.manualpdfGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.manualbillselectedRowdata.length == 0) {
       this.getMaunualListExport();
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.manualpdfGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.manualpdfGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }

  private exportSubscriptionAdhoc: Subscription = new Subscription;
  private exportSubscriptionBill: Subscription = new Subscription;
  private exportSubscriptionManual: Subscription = new Subscription;
  adhocExport: any[] = [];
  manualExport: any[] = [];
  billExport: any[] = [];
  getBillsStoreInfo() {

    this.store.dispatch(resetbillsState());

    this.store.dispatch(resetbillsAdhocState());

    this.store.dispatch(resetbillsManualState());

    let getBills$ = this.store.pipe(select(getBillsResponse));
    let getManualBills$ = this.store.pipe(select(getManualResponse));
    let getAdhocBills$ = this.store.pipe(select(getAdhoctResponse));

    this.exportSubscriptionBill = this.store.pipe(select(getAdhoctExportResponse)).subscribe({
      next: (res: any) => {
        if (res) {
          this.adhocExport = [];
          if (res.items.lengh > 0) {
            this.adhocExport = [...res.items];
            const transformedData = this.adhocExport.map((item: any) => ({
              'Bill No': item.billNo,
              'Residential Unit': item.residentialUnit,
              'Utility': item.utility,
              'Meter No': item.macAddress,
              'Bill Amount': item.billAmount,
              'Mode of Payment': item.modeOfPayment,
              'Transaction Id': item.transactionId,
              'Payment Date': this.sharedService.dateFormateChnage(item.paidDate),
              'Bill Generated Date': this.sharedService.dateFormateChnage(item.currentBillGeneratedDate),
              'Bill Month': item.billMonth,
              'Status': item.paymentStatus,
              'Released Status': item.isReleased == true ? "Released " : " Not Released",
              // consumerName: item.consumerName,

            }));
            this.PdfexportService.downloadExcel(transformedData, 'Adhoc');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.INFO
            );
          }
        }
      },
    });
    this.exportSubscriptionManual = this.store.pipe(select(getManualExportResponse)).subscribe({
      next: (res: any) => {
        if (res) {
          this.manualExport = [];
          if (res.items.length) {
            this.manualExport = [...res.items];
            const transformedData = this.manualExport.map((item: any) => ({
              'Bill No': item.billNo,
              'Residential Unit': item.residentialUnit,
              'Utility': item.utility,
              'Meter No': item.macAddress,
              'Consumer': item.consumerName,
              'Bill Amount': item.billAmount,
              'Mode of Payment': item.modeOfPayment,
              'Transaction Id': item.transactionId,
              'Payment Date': this.sharedService.dateFormateChnage(item.paidDate),
              'Bill Generated Date': this.sharedService.dateFormateChnage(item.currentBillGeneratedDate),
              'Bill Month': item.billMonth,
              'Status': item.paymentStatus,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'manual');
          }
          else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.INFO
            );
          }
        }
      }
    });





    this.exportSubscriptionBill = this.store.pipe(select(getBillsExportResponse)).subscribe({
      next: (res: any) => {
        if (res) {
          this.billExport = [];
          if (res.items.length) {
            this.billExport = [...res.items];
            const transformedData = this.billExport.map((item: any) => ({
              'Bill No': item.billNo,
              'Residential Unit': item.residentialUnit,
              'Utility': item.utility,
              'Consumer': item.consumerName,
              'Meter No': item.macAddress,
              'Bill Amount': item.billAmount,
              'Mode of Payment': item.modeOfPayment,
              'Transaction Id': item.transactionId,
              'Payment Date': this.sharedService.dateFormateChnage(item.paidDate),
              'Bill Generated Date': this.sharedService.dateFormateChnage(item.currentBillGeneratedDate),
              'Consumption b/w': item.consumptioDate,
              'FromToDate': item.FromToDate,
              'bill Month': item.billMonth,
              'Payment Status': item.paymentStatus,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Bills');
          }
          else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.INFO
            );
          }

        }
      },
    });
    getBills$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.billsList = [...res.items];
        this.pageSize = res.pageSize;
        this.billstotalUserCount = res.totalCount;
        this.loadBillsGridTable();
      }
      console.log('consumerList', res, this.billsList);
    });
    getManualBills$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.manualBillsList = [...res.items];
        this.billGenType = this.manualBillsList[0]?.billGenType;
        this.manualpageSize = res.pageSize;
        this.manualBillstotalUserCount = res.totalCount;
        this.loadManualBillsGridTable();
      }
      console.log('consumerList', res, this.manualBillsList);
    });
    getAdhocBills$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.adhocBillsList = [...res.items];
        this.billGenType = this.adhocBillsList[0]?.billGenType;
        this.adhocpageSize = res.pageSize;
        this.adhocbillstotalUserCount = res.totalCount;
        this.loadAdhocBillsGridTable();
      }
      console.log('consumerList', res, this.adhocBillsList);
    });
  }

  ngOnDestroy() {
    this.exportSubscriptionAdhoc.unsubscribe();
    this.exportSubscriptionBill.unsubscribe();
    this.exportSubscriptionManual.unsubscribe()// Or set it to [] if you prefer an empty array
  }
  isReleasebills: boolean = false
  loadBillsGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    console.log(this.billsList)
    const processedData = process(this.billsList, state);
    this.billstgridData = {
      data: this.billsList,
      total: this.billstotalUserCount,
    };

    this.billstgridData.data = this.billstgridData?.data.map((project) => {
      return {
        ...project,
        paidDate: project?.paidDate == '0001-01-01T00:00:00' ? '-' : this.sharedService.dateFormateChnage(project.paidDate),
        paymentDate: project?.paidDate == '0001-01-01T00:00:00' ? '-' : this.sharedService.dateFormateChnage(project.paidDate),
        isReleased: project?.isReleased == true ? "Released " : " Not Released",
        billGeneratedDate: project?.currentBillGeneratedDate != null ? this.sharedService.dateFormateChnage(project.currentBillGeneratedDate) : '-'
      };
    });
    // console.log(this.billstgridData.data)
    if (this.billstgridData.data.length > 0 && this.billstgridData.data[0].isReleased) {
      this.isReleasebills = true
    }
    console.log(this.isReleasebills)
    this.loaderService.setLoading(false);
  }

  loadManualBillsGridTable() {
    const state: any = {
      take: this.manualpageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.manualBillsList, state);
    this.manualBilsgridData = {
      data: this.manualBillsList,
      total: this.manualBillstotalUserCount,
    };

    this.manualBilsgridData.data = this.manualBilsgridData?.data.map((bills) => {
      return {
        ...bills,
        paidDate: bills?.paidDate == '0001-01-01T00:00:00' ? '-' : bills.paidDate
      };
    });
    this.loaderService.setLoading(false);
  }
  loadAdhocBillsGridTable() {
    const state: any = {
      take: this.adhocpageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.adhocBillsList, state);
    this.adhocBillsgridData = {
      data: this.adhocBillsList,
      total: this.adhocbillstotalUserCount,
    };
    this.adhocBillsgridData.data = this.adhocBillsgridData?.data.map((project) => {
      return {
        ...project,
        paidDate: project?.paidDate == '0001-01-01T00:00:00' ? '-' : project.paidDate,
        paymentDate: project?.paidDate == '0001-01-01T00:00:00' ? '-' : this.sharedService.dateFormateChnage(project.paidDate),
        billGeneratedDate: project?.currentBillGeneratedDate != null ? this.sharedService.dateFormateChnage(project.currentBillGeneratedDate) : '-',
        consumptioDate: project?.billGeneratedFromDate != null && project.billGeneratedToDate != null
          ? `${this.sharedService.dateFormateChnage(project.billGeneratedFromDate)} - ${this.sharedService.dateFormateChnage(project.billGeneratedToDate)}`
          : '-'
      };
    });
    this.loaderService.setLoading(false);
  }

  public onFilterBill(value: Event): void {
    const inputValue = value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ProjectCode',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Region',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Utility',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectType',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ProjectStatus',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  billselectedRowdata: any = [];
  billsdeselectedRowdata: any = [];
  billsonSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.billselectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.billselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.billsdeselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.billsdeselectedRowdata);
      });

      if (this.billsdeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.billsdeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.billselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.billselectedRowdata = filteredArray;
      }
    }
  }
  onSelect(event: any) {
    console.log(event);
  }
  manualbillselectedRowdata: any = [];
  manualbillsdeselectedRowdata: any = [];
  manualbillsonSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.manualbillselectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.manualbillselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.manualbillsdeselectedRowdata.push(element.dataItem);
        console.log(
          'deselectedRowdata Values : ',
          this.manualbillsdeselectedRowdata
        );
      });

      if (this.manualbillsdeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.manualbillsdeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.manualbillselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.manualbillselectedRowdata = filteredArray;
      }
    }
    console.log(this.manualbillselectedRowdata);
  }

  adhocbillselectedRowdata: any = [];
  adhocbillsdeselectedRowdata: any = [];
  adhocbillsonSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.adhocbillselectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.adhocbillselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.adhocbillsdeselectedRowdata.push(element.dataItem);
        console.log(
          'deselectedRowdata Values : ',
          this.adhocbillsdeselectedRowdata
        );
      });

      if (this.adhocbillsdeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.adhocbillsdeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.adhocbillselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.adhocbillselectedRowdata = filteredArray;
      }
    }
  }

  protected billsPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.billscurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getBillsList();
    this.loadBillsGridTable();
  }

  protected manualconsumerPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.manualpageSize = take;
    this.maulabillscurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getMaunualList();
    this.loadManualBillsGridTable();
  }

  protected adhocPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.adhocpageSize = take;
    this.adhocbillscurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAdhoclist();
    this.loadAdhocBillsGridTable();
  }

  protected billsStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadBillsGridTable();
  }

  protected manualbillStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadManualBillsGridTable();
  }

  protected adhocbillStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadAdhocBillsGridTable();
  }

  deleteUtilities() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  public bilsonFilter(value: any): void {
    const inputValue = value;
    // this.billsearchedValue = value;
    this.store.dispatch(
      getBillslist({
        projectId: this.projectId,
        pageIndex: this.billscurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.billsearchedValue,
        billType: 'bills',
      })
    );
  }
  public manualbilsonFilter(value: any): void {
    const inputValue = value;
    //this.manualsarchedValue = value;
    this.store.dispatch(
      getManuallist({
        projectId: this.projectId,
        pageIndex: this.maulabillscurrentPage,
        pageSize: this.manualpageSize,
        searchTerm: this.manualsarchedValue,
        billType: 'manual',
      })
    );
  }
  public adhocbilsonFilter(value: any): void {
    const inputValue = value;
    //this.adhocSearchedValue = value;
    getAdhoclist({
      projectId: this.projectId,
      pageIndex: this.adhocbillscurrentPage,
      pageSize: this.adhocpageSize,
      searchTerm: this.adhocSearchedValue,
      billType: 'adhoc',
      filter: this.adhocqueryString,
      orderBy: this.orderByQuery,
    });
  }



  // public onExport() {
  //   this.hiddenGrid?.saveAsPDF();
  // }

  viewBilsdeatils(dataItem: any): void {
    let data = dataItem;
    if (this.stepper) {
      localStorage.setItem('selectedTabIndex', this.stepper.selectedIndex.toString());
    }
    this.router.navigate(['/bills/billsDetails'], { state: { bilss: data } });
  }

  releaseBills() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You want to Release Bills ",
        icon: 'warning',
        confirmButtonText: 'Yes, retry it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          this.loaderService.setLoading(true);
          let request = {
            ProjectId: this.projectId,
            UserId: this.userId
          }
          this.billsService.releaseBills(request).subscribe({
            next: (response) => {
              if (response && response.statusCode === 201) {
                console.log(response);
                this.store.dispatch(
                  getBillslist({
                    projectId: this.projectId,
                    pageIndex: 1,
                    pageSize: 10,
                    searchTerm: '',
                    billType: 'bills',
                  })
                );
              }

            }, error: (err) => {
              this.loaderService.setLoading(false)

            },
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.loaderService.setLoading(false)

        }
      });




  }
  downloadBillsPaymentrecceipt(data: any,) {
    //event.stopPropagation();
    let temp = {
      // status: data.paymentStatus,
      paymentId: data.transactionId
    }
    //downloadBillsPayment

    this.sharedService.downloadBillsPaymentrecceipt(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'PaymentReceipt.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }


  downloadbillsumary(data: any,) {
    //event.stopPropagation();
    let temp = {
      id: data.billNo,
      status: data.paymentStatus,
      // paymentId: data.transactionId
    }
    //downloadBillsPayment

    this.sharedService.downloadBillsPayment(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'PaymentReceipt.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }


  createBill(billType: string) {
    let sendData = {
      billType: billType,
      billgeneratetype: this.billGenType,
    };
    if (this.stepper) {
      localStorage.setItem('selectedTabIndex', this.stepper.selectedIndex.toString());
    }
    this.router.navigate(['bills/createBill'], { state: { billsData: sendData } });
  }
  /* viewManualdeatils(dataItem: any, ): void {

    let data = dataItem;
    data['billType'] = billType;
    this.router.navigate(['/bills/billsDetails'], { state: { bilss: data } })

  }

  viewadhocdeatils(dataItem: any, ): void {
    let data = dataItem;
    data.billType = billType;
    this.router.navigate(['/bills/billsDetails'], { state: { bilss: data } })

  } */

  onbillsSortChange(sort: SortDescriptor[]): void {
    this.bill.sort = sort;
    console.log(this.bill.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(this.bill.sort);

    this.orderByQuery = orderByQuery;
    this.store.dispatch(
      getBillslist({
        projectId: this.projectId,
        pageIndex: this.billscurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.billsearchedValue,
        billType: 'bills',
        filter: this.billqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }
  public onadhocFilterChange(filter: CompositeFilterDescriptor): void {
    this.adhocbill.filter = filter;
    console.log(this.adhocbill);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);

    const queryString = this.sharedService.createQuery(this.adhocbill);
    this.adhocqueryString = this.sharedService.createQuery(this.adhocbill);
    this.store.dispatch(
      getAdhoclist({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: this.adhocpageSize,
        searchTerm: this.adhocSearchedValue,
        billType: 'adhoc',
        filter: this.adhocqueryString,
        orderBy: this.orderByQuery,
      })
    );
    console.log(queryString);
  }

  onAdhocSortChange(sort: SortDescriptor[]): void {
    this.adhocbill.sort = sort;
    console.log(this.adhocbill.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.adhocbill.sort
    );

    this.orderByQuery = orderByQuery;
    this.store.dispatch(
      getAdhoclist({
        projectId: this.projectId,
        pageIndex: this.adhocbillscurrentPage,
        pageSize: this.adhocpageSize,
        searchTerm: this.adhocSearchedValue,
        billType: 'adhoc',
        filter: this.adhocqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  public onbillsFilterChange(filter: CompositeFilterDescriptor): void {
    this.bill.filter = filter;
    this.skip = 0
    console.log(this.bill);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);

    const queryString = this.sharedService.createQuery(this.bill);
    this.billqueryString = this.sharedService.createQuery(this.bill);
    this.store.dispatch(
      getBillslist({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.billsearchedValue,
        billType: 'bills',
        filter: this.billqueryString,
        orderBy: this.orderByQuery,
      })
    );
    console.log(queryString);
  }

  public onmanualFilterChange(filter: CompositeFilterDescriptor): void {
    this.manualbill.filter = filter;
    this.skip = 0
    console.log(this.manualbill);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);


    const queryString = this.sharedService.createQuery(this.manualbill);
    this.manualqueryString = this.sharedService.createQuery(this.manualbill);
    this.store.dispatch(
      getManuallist({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: this.manualpageSize,
        searchTerm: this.manualsarchedValue,
        billType: 'manual',
        filter: this.manualqueryString,
        orderBy: this.orderByQuery,
      })
    );
    console.log(queryString);
  }

  onManualSortChange(sort: SortDescriptor[]): void {
    this.manualbill.sort = sort;
    console.log(this.manualbill.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.manualbill.sort
    );

    this.orderByQuery = orderByQuery;
    this.store.dispatch(
      getManuallist({
        projectId: this.projectId,
        pageIndex: this.maulabillscurrentPage,
        pageSize: this.manualpageSize,
        searchTerm: this.manualsarchedValue,
        billType: 'manual',
        filter: this.manualqueryString,
        orderBy: this.orderByQuery,
      })
    );
  }
}
