import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { createFaqs, updateFaqs } from '../../../store/Faq/faq.actions';
import {
  createFaqError,
  createFaqResponse,
} from '../../../store/Faq/faq-selector';
import { Helper } from '../../../core/helpers/helper';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { ConfirmationModal } from '../../../shared/modals/common';

@Component({
  selector: 'app-rp-create-faq',
  templateUrl: './rp-create-faq.component.html',
  styleUrls: ['./rp-create-faq.component.scss'],
})
export class RpCreateFaqComponent implements OnInit {
  uploadSaveUrl = 'saveUrl'; // Replace with actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // Replace with actual API endpoint
  faqForm!: FormGroup;
  submitted = false;
  faqId = '';
  error$: any;
  breadCrumbItems: Array<{}> | undefined;
  listOffiles: any = [];
  disableFile: boolean = false;
  receivedData: any;
  constructor(
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getStoreInfo();
  }

  ngOnInit(): void {
    this.faqForm = this.formBuilder.group({
      Title: [
        '',
        [
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.required,
          Helper.noWhitespaceValidator(),
          // Helper.PatternValidator(/^[a-zA-Z\s]*$/),
        ],
      ],
      Content: ['', [Validators.required, Helper.noWhitespaceValidator()]],
      Files: [''],
      FilesToDelete: [],
    });

    // this.route.queryParams.subscribe((params: any) => {
    //   if (params) {
    // const receivedData = params as any; // Adjust type as needed

    this.receivedData = history.state.faqsdata;
    this.faqForm.patchValue({
      Title: this.receivedData?.title,
      Content: this.receivedData?.content || '',
      Files: this.receivedData?.fileUrl || '',
      FilesToDelete: [],
    });
    this.faqId = this.receivedData?.id;
    this.listOffiles = this.receivedData?.files;
    this.breadCrumbItems = [
      { label: 'FAQ', url: 'faq', active: true },
      { label: this.faqId ? 'Edit FAQ' : 'Add FAQ' },
    ];

    //   }
    //   debugger;
    // });
  }

  handleFileUploadSuccess(event: any): void {
    console.log('File uploaded successfully', event);
    this.disableFile = false;
  }

  handleFileUploadError(event: any): void {
    console.log('File upload error', event);
    this.disableFile = false;
  }

  removeUploadedFile(id: string): void {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.WARNING,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };

    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      if (result.isConfirmed) {
        // Find the index of the file with the matching id
        const index = this.listOffiles.findIndex((file: any) => file.id === id);

        // If the file is found, remove it from the list and push its id to FilesToDelete
        if (index !== -1) {
          // Remove the file from the list
          this.listOffiles.splice(index, 1);

          // Push the deleted file's id to FilesToDelete
          const filesToDelete = this.faqForm.get('FilesToDelete')?.value || [];
          filesToDelete.push(id);
          this.faqForm.get('FilesToDelete')?.setValue(filesToDelete);
        }
      }
    });
  }

  get f() {
    return this.faqForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.faqForm.valid) {
      this.loaderService.setLoading(true);
      this.store.dispatch(createFaqs({ faq: this.faqForm.value }));
    }
  }

  updateFaq() {
    this.submitted = true;
    if (this.faqForm.valid) {
      this.loaderService.setLoading(true);
      this.store.dispatch(
        updateFaqs({ faq: this.faqForm.value, id: this.faqId })
      );
    }
  }

  handleFileSelected(event: any) {
    console.log(event);
    this.disableFile = true;

    // Handle file selected logic
  }

  handleFileRemoved(event: any) {
    // Handle file removed logic
  }

  navToFaqPage() {
    this.router.navigate(['faq']);
  }

  getStoreInfo() {
    this.error$ = this.store.pipe(select(createFaqError));
    const createFaq$ = this.store.pipe(select(createFaqResponse));

    createFaq$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      console.log(res);
    });

    this.error$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      console.log('err', res);
    });
  }
}
