import { MaxLengthValidator, PatternValidator } from '@angular/forms';
import { min } from 'rxjs';

export const Validations = {
  LoginPage: {
    email_mobile_validation: 'Email / Mobile Number is required',
    password_required_validation: 'Password is required',
    otp_pattern: 'Only numbers(allow 0 - 9 numbers)',
    otp_required_validation: 'Otp is required',
    otp_length_validation: 'Otp should be 6 digits',
    otp_success_msz: 'OTP has been sent to your email and Phone number.',
  },
  ForgotPwdPage: {
    email_mobile_validation: 'Email / Mobile Number is required',
    otp_success_msz: 'OTP has been sent to your email and Phone number.',
  },
  ResetPwdPage: {
    otp_required_validation: 'Otp is required.',
    newpwd_required_validation: 'Password is required.',
    confirmPwd_required_validation: 'Confirm Password is required.',
    matchPwd_validation: 'Confirm Password should match with Password.',
    pwd_validations:
      'Password Must include one uppercase letter, one lowercase letter, one special character,one digit and be at least 6 characters long.',
  },
  billCharge: {
    name: 'Name is required',
    amount: 'Amount is required',
    gst: 'GST is required',
    utilityType: 'Utility is required',
    patten: 'Only numbers(allow 1 - 28 numbers)',
    slot_pattern: 'Only numbers allowed',
    total: 'Total is required',
    ProjectId: 'Project Id is Required',
    reason: 'Reason is required',
    // totalPattren: 'Only numbers(allow 1 - 28 numbers)',
    OnlyAlphanumeric_patten: 'Only alphanumeric characters are allowed',
    Onlynumberwithdecimals: 'Only numbers with decimals are allowed',
    dateRange: 'From and to Date  is required',
    slot: 'Slot is required',
    file: 'File is required',
    max_amount: 'Amount cannot exceed',
    min_amount: 'Amount must be at least',

    PenaltyApplicable_dateOthers: ' Select Penalty Applicable date Others ',
    PenaltyApplicable_dateWater: 'Select Penalty Applicable date Water ',
    PenaltyApplicable_dateGas: 'Select Penalty Applicable date Gas ',
    PenaltyApplicable_dateElectricity:
      'Select  Penalty Applicable date Electricity ',
    PenalityAmountForElectricity: 'Penality Amount for Electricity is required',
    PenalityAmountForGas: 'Penality Amount for Gas is required',
    PenalityAmountForWater: 'Penality Amount for Water is required',
    PenalityAmountForOthers: 'Penality Amount for Others is required',
    PenalityAmount: 'Penality Amount is required',
    Penalitydate: 'Penality date is required',
  },

  bill: {
    totalAmount: ' Total Amount is required',
    billStatus: ' Bill Status is required',
    consumer: ' Consumer is required',
    utility: ' Utility is required',
    residentialUnit: 'Residential Unit is required',
    mterNumber: ' Meter Number is required',
    selectBillForMonth: ' Select Bill for Month is required',
    generalCharge: ' General Charges is required',
    billDays: ' Bill Days is required',
    SelectDatesForBillGaneration:
      ' Select Dates for Bill generators is required',

    paid: 'The bill for the selected month has already been paid. You can generate the next bill only in the upcomming billing cycle',
    emptyBillStatus:
      'The bill for the selected Month has not been generated yet. Please generate an adhoc bill to proceed.',
    unPiad:
      'A bill for the selected month has already generated. To proceed, please mark the existing bill as invalid and generate a new one.',
  },

  residentialUnit: {
    flat_House: 'Flat/ House# is required.',
    floor: 'Floor is required.',
    block: 'Block is required',
    tower: 'Tower is required',
    patten: 'Only alphanumeric, special chars (#-,) & space',

    OnlyAlphanumeric_patten: 'Only Alpha numeric are allowed',
    OnlyNumberDecimal_patten: 'Only Number with 2 decimals allowed',

    utilityType: 'utilityType is required',
    utilitymeters: 'utility Meters is required',
    occupantDropdown: 'occupant name  is required',
    move_in_date: 'Move in date is required',
    InstalledLocation: 'Installed Location  is required',
    InitialReading: 'Initial reading is required',
    VacateDate: 'Vacate Date is required',
    owner_name: 'Owner Name is required',
    MeterinitialReadingDate: 'Meter Initial Reading date is required',
    Meter_Installation_Date: 'Meter installation date is required',
    Meter_Initial_Reading_DG_KWH: 'Meter Initial Reading DG KWH is required',
    Meter_Initial_Reading_EB_KWH: 'Meter Initial Reading EB KWH is required',
  },

  consumersPage: {
    title: 'Title is required',
    Occupied_residential_unit: 'Occupied residential unit  is required',
    Registered_date: 'Registered date  is required',
    enable_wallet: 'enable_wallet id is required',
    feedback: 'Feedback is required',
    min_feedback: '  Feedback must contain minimum 2 characters',
    co_name: {
      Enter_co_name_required_Validations: 'C/O Name is required',
      min_required_Validations:
        'Enter C/O Name Should Conatines min 1 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces allowed',
    },
    mobile_validation: {
      mobile_required_validations: 'Mobile number primary is required',
      mobile_mix_required_Validations:
        'Valid Mobile number  Should Conatines min 10 and max 10 characters',
      numbers_only: 'Only numbers',
      mobile_format: 'Please enter valid mobile number',
    },
    countryCode: {
      countryCode_required_validations: 'country Code is required',
      secoundary_countryCode_required_validations: 'Country code is required for secondary phone number.',
    },
    surName: {
      surName_required_Validations: 'surName is required',
      min_required_Validations:
        'surName Should Conatines min 2 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces Allowed',
    },
  },

  ProfilePage: {
    firstName: {
      firstName_required_Validations: 'First Name is required',
      min_required_Validations:
        ' First name should contain min 2 and max 30 characters',
      allow_spaces: 'Only Alpbets & 1-spaces allowed',
    },
    lastName: {
      lastName_required_Validations: 'Last Name is required',
      min_required_Validations:
        'Last Name  Should contain min 2 and max 30 characters',
      allow_spaces: 'Only Alpbets & 1-spaces allowed',
    },
    secondaryPhoneNumber: {
      lastName_required_Validations: 'secondary PhoneNumber is required',
      min_required_Validations:
        'PhoneNumber  Should contain 10 characters',
      allow_spaces: 'Only numbers allowed',
    },
    secondaryCountryCode: {
      secondaryCountryCode_required_Validations: 'Country Code is required',
    },
    emmail_validation: {
      email_required_validations: 'Email Address is required',
      email_pattern: 'Email Must be a valid email address',
    },
    mobile_validation: {
      mobile_required_validations: 'Mobile Number is required',
      mobile_mix_required_Validations:
        'Invalid mobile number. It should contain only digits.',
      numbers_only: 'Only numbers',
      same_MobileNumber:
        'primary and Secondary Mobile number should  be different',
      same_Email: 'primary and Secondary Email should  be different',
    },
    countryCode: {
      mobile_code_required_validations: 'Country Code is required',
    },
    role: 'Role is required',
    password_validation: {
      password_required_validations: 'Password is required',
      password_Invalid_pattern: 'Password Invalid!',
    },
    project: {
      project_required_validations: 'Project is required',
    },
  },
  ChangePwdPage: {
    oldPassword: {
      required: 'Old Password is required',
      minLengthValidator: 'Old Password should contain min of 8 characters',
      MaxLengthValidator: 'Old Password should contain max of 16 characters ',
    },
    newPassword: {
      required: 'New Password is required',
      minLengthValidator: 'New Password should contain min of 8 characters',
      MaxLengthValidator: 'New Password should contain max of 16 characters ',
      patternValidation: 'Password Must include one uppercase letter, one lowercase letter, one special character,one digit and be at least 6 characters long.',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minLengthValidator: 'Confirm Password should contain min of 8 characters',
      MaxLengthValidator:
        'Confirm Password should contain max of 16 characters ',
      patternValidation: 'Password Must include one uppercase letter, one lowercase letter, one special character,one digit and be at least 6 characters long.',
    },

    match_required_validations:
      'Confirm Password should match with New Password',
    oldPwdandNewPwd_validations:
      'Old password and new password cannot be the same.',
  },
  gatewayPage: {
    serialNumber_required_Validations: 'serial Number is required',
    min_required_Validations:
      'First Name  Should Conatines min 2 and max 40 characters',
    allow_spaces: 'Only alpha numeric are allowed',
  },
  ServiceError: {
    // Error: 'SomeThing Went wrong please contact administrator',
    Error: '',
  },

  Project_ValidationMessages: {
    project_Name: {
      required: 'Project name is required',
      minLengthValidator: 'Project name should contain min of 2 characters',
      MaxLengthValidator: 'Project name should contain max of 50 characters ',
      patternValidation: 'Only alpha numeric & special chars(-_&) allowed',
    },
    project_Code: {
      patternValidation: 'Only alpha numeric allowed',
    },

    projectType: {
      required: 'Project type is required',
    },
    utility: {
      required: 'utility is required',
    },
    Status: {
      required: 'Project Status is required',
    },
    StartDate: {
      required: 'Project Start Date is required',
    },
    region: {
      required: 'Region is required',
    },
    address: {
      required: 'Project address is required',
    },
    area: {
      required: 'Area / Locality is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    city: {
      required: 'City is required',
    },
    state: {
      required: 'State is required',
    },
    pincode: {
      required: 'Pincode is required',
      minLengthValidator: 'Pincode should contain 6 digits',
      MaxLengthValidator: 'Pincode should contain 6 digits ',
      patternValidation:
        'Only Numeric, Enter a valid pincode (If not entered 6 characters)',
    },
    ContactNumber: {
      required: 'Mobile number  is required',
      mobile_mix_required_Validations:
        'Valid Mobile number Format max 10 characters',
      patternValidation: 'Only numbers',
    },
    society_name: {
      required: 'Society head details - Name is required',
      minLengthValidator: 'Society name should contain min of 2 characters',
      MaxLengthValidator: 'Society name should contain max of 20 characters ',
      patternValidation: 'Only alpha numeric & spaces allowed',
    },
    merchantId: {
      required: 'Merchant ID is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    GSTTINO: {
      patternValidation: 'Only alpha numeric allowed',
    },
    accountName: {
      required: 'Account name is required',
      patternValidation: 'Only alpha numeric & spaces allowed',
    },
    accountNumber: {
      required: 'Account number is required',
      patternValidation: 'Only number allowed',
    },
    bankName: {
      required: 'Bank name is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    branchName: {
      required: 'Branch name is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    ifscCode: {
      required: 'IFSC Code is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    billingType: {
      required: 'Billing type is required',
    },
    BillGenerationTypeId: {
      required: 'Bill Generation Type is required',
    },
    minRechargeAmount: {
      required: 'Minimum recharge amount is required',
      patternValidation:
        'Minimum Recharge Amount must be a number with up to 2 decimal places',
    },
    minWalletBalance: {
      required: 'Minimum wallet balance is required',
      patternValidation:
        'Minimum wallet balance must be a number with up to 2 decimal places',
    },
    valveCutofflimit: {
      required: 'Valve cut Off limit is required',
      patternValidation:
        'Minimum valve cut off limit must be a number with up to 2 decimal places',
    },
    relayCutofflimit: {
      required: 'Relay cut Off limit is required',
      patternValidation:
        'Minimum Relay cut off limit must be a number with up to 2 decimal places',
    },
    maxRechargeAmount: {
      required: 'Maximum recharge amount is required',
      patternValidation:
        'Maximum  Recharge Amount must be a number with up to 2 decimal places',
    },
    waterConsumptionLimit: {
      required: 'Water Consumption Limit is required',
      patternValidation:
        'Water Consumption Limit must be a number with up to 2 decimal places',
    },
    gasConsumptionLimit: {
      required: 'Gas Consumption Limit is required',
      patternValidation:
        'Gas Consumption Limit  must be a number with up to 2 decimal places',
    },
    electricityDGConsumptionLimit: {
      required: 'Electricity DG Consumption Limit is required',
      patternValidation:
        'Electricity DG Consumption must be a number with up to 2 decimal places',
    },
    electricityEBConsumptionLimit: {
      required: 'Electricity EB Consumption Limit is required',
      patternValidation:
        'Electricity EB Consumption must be a number with up to 2 decimal places',
    },
    billDate: {
      required: 'Project billing date is required',
    },
    DueDate: {
      required: 'Project Due date is required',
    },
    ValveCutOffDate: {
      required: 'Valve CutOff Date is required',
    },
    RelayCutOffDate: {
      required: 'Relay CutOff Date is required',
    },
    db_password: {
      required: 'Database Password  is required',
    },
    db_serverName: {
      required: 'Database Server name is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    db_userName: {
      required: 'Database user name is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    provider_settings: {
      required: 'Please select At least one utility in project detail tab',
    },
    gasProvider: {
      required: 'Gas provider is required',
    },
    electricityProvider: {
      required: 'Electricity provider is required',
    },
    waterProvider: {
      required: 'Water provider is required',
    },
    required_all_mandatoryFields:
      'Please fill all Mandatory fields in all tabs',
  },

  MasterCreatePage: {
    masterName: {
      required: 'Master name is required',
      minLengthValidator: 'Master name should contain min of 2 characters',
      MaxLengthValidator: 'Master name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    masterType: {
      required: 'Master Type is required',
    },
  },

  SupplierCreatePage: {
    SupplierName: {
      required: 'Supplier name is required',
      minLengthValidator: 'Supplier name should contain min of 2 characters',
      MaxLengthValidator: 'Supplier name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    masterId: {
      required: 'Master is required',
    },
  },

  MonitoringCreatePage: {
    MeterNumber: {
      required: 'Meter Number is required',
      minLengthValidator: 'Meter Number should contain min of 2 characters',
      MaxLengthValidator: 'Meter Number should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    utilityId: {
      required: 'Utility is required',
    },
  },

  Mapping: {
    utilityId: {
      required: 'Utility Measuring Device is required',
    },
  },

  gas: {
    gasValue: {
      required: 'Gas value is required',
      minLengthValidator: 'Gas value should contain min of 1 characters',
      MaxLengthValidator: 'Gas value should contain max of 20 characters ',
      patternValidation:
        'only numbers allowed and starts with 0(zero) not allowed',
    },
    UnitLPGValue: {
      required: '1 kg / LPG is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    unitPrice: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with / without decimals allowed  and the value should not be zero',
    },
  },
  water: {
    slabName: {
      required: 'Supplier Name is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    MinValue: {
      required: 'Min is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    MaxValue: {
      required: 'Max is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    slabPerUnit: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with decimals allowed and the value should not be zero',
    },
  },
  electricity: {
    slabName: {
      required: 'Supplier Name is required',
    },
    minKwh: {
      required: 'Min kWh is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    maxKwh: {
      required: 'Max kWh is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    UnitPrice: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with decimals allowed and the value should not be zero',
    },
  },
  umd: {
    utility: {
      required: 'Utility is required',
    },
    macAddress: {
      required: 'Mac address is required',
    },
    meterStatus: {
      required: 'Meter status is required',
    },
  },

  UmdCreatePage: {
    utilityMasterId: {
      required: 'Utility Master is required',
    },
    macAddress: {
      required: 'Mac Address is required',
      pattern: 'Mac Address not allowed Special Characters'
    },
    meterSerialNo: {
      required: 'Meter Serial Number is required',
      pattern: 'Meter Serial Number not allowed Special Characters'
    },
    meterStatus: {
      required: 'Meter Status is required',
    },
    mcbMainPower: {
      required: 'MCB/Main Power (On/Off button) is required',
    },
    supplierId: {
      required: 'Supplier Name is required',
    },
    gateWayname: {
      required: 'Gateway Name is required',
    }
  },

  exportActivationStatusFormValidations: {
    selectedUtility: {
      required: ' Please select at least one utility.',
    },
    activationDateRange: {
      required: ' Please select a date range.',
    },
  }

};
