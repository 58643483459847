import { Component, ViewChild } from '@angular/core';
import { FilterableSettings, DataBindingDirective } from "@progress/kendo-angular-grid";
import { SVGIcon, filePdfIcon, fileExcelIcon } from "@progress/kendo-svg-icons";

import { PaymentService } from '../../../core/services/payment.service';
import { AppConfigData } from '../../../core/config/appConfigData';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { getwalletHistorylist } from '../../../store/Wallet/wallet.action';
import Swal from 'sweetalert2';
//import { CurrencyPipe } from '@angular/common';

import { walletHistroeyResponse } from '../../../store/Wallet/wallet-selector';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Validations } from '../../../shared/constants/validations';
import { error } from 'console';
import { Observable } from 'rxjs';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { SharedService } from '../../../shared/shared.service';
import orders from 'razorpay/dist/types/orders';
import { escape } from 'querystring';
import { LoaderService } from '../../../core/services/loader.service';
import { RpAlertsComponent } from '../../../shared/components/rp-alerts/rp-alerts.component';
declare var Razorpay: any
@Component({
  selector: 'app-rp-wallet',
  templateUrl: './rp-wallet.component.html',
  styleUrl: './rp-wallet.component.scss',
  providers: [DecimalPipe]

})
export class RpWalletComponent {
  ServiceError = Validations.ServiceError;
  amountTempArray = [{ amount: 500 }, { amount: 1000 }, { amount: 2000 }]
  amountValues: any[] = []
  walletForm!: UntypedFormGroup;
  public filterMode: FilterableSettings = "row";
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  max_amount: any;
  paymentlaertModal!: BsModalRef;
  min_amount: any;
  paymentDetails: any
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  submitted: boolean = false;
  selectValue!: string[];
  amount: any;
  currentUserData: any
  projectId: any;
  userId: any;
  userPhone: any;
  useremail: any;
  max_amount_error: boolean = false;
  userName: any;
  walletHistoryList: any;
  undefinde: any;
  regex = /^[0-9]*$/;
  regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
  breadCrumbItems: Array<{}> | undefined;
  constructor(private decimalPipe: DecimalPipe,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private paymentService: PaymentService, private router: Router, private store: Store, private sharedService: SharedService,) {
    this.loaderService.setLoading(true);
    this.breadCrumbItems = [{ label: 'Wallet Details' }];
    if (this.walletHistoryList?.maxRechargeAmount > 0) {
      this.walletForm = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern(this.regexp), Validators.min(this.min_amount), Validators.max(this.max_amount)]],
      })
    }
    let gewalletHistroy$ = this.store.pipe(select(walletHistroeyResponse));
    gewalletHistroy$.subscribe((response: any) => {

      console.log(response)
      this.walletHistoryList = response;
      this.max_amount = this.walletHistoryList?.maxRechargeAmount;
      this.min_amount = this.walletHistoryList?.minRechargeAmount;
      let filterAmount = this.amountTempArray.filter((data: any) => this.walletHistoryList?.maxRechargeAmount >= data.amount)
      //console.log(filterAmount)
      this.walletForm = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern(this.regexp), Validators.min(this.min_amount), Validators.max(this.max_amount)]],
      })
      this.amountValues = filterAmount;
      console.log(this.min_amount)

      this.loaderService.setLoading(false);
    })

  }
  prifileValidations = Validations;
  ngOnInit(): void {
    this.loaderService.setLoading(true);
    console.log(this.min_amount)

    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.currentUserRoleDetails !== null) {
      this.useremail = this.currentUserData.currentUserRoleDetails.email;
      this.userId = this.currentUserData.currentUserRoleDetails.userId;
      this.userName = this.currentUserData.currentUserRoleDetails.userName;
      this.userPhone = this.currentUserData.currentUserRoleDetails.phone;
    }
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
    }
    this.store.dispatch(getwalletHistorylist({ consumerId: this.userId, projectId: this.projectId }));

  }
  get walletFormConntrols() {
    return this.walletForm.controls;
  }


  paymentAlertSucess(data: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
        popup: 'custom-popup',

      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons.fire({
      allowOutsideClick: false,
      //title: 'your wallet recharge is successfull',
      icon: 'success',
      text: `Success`,
      html: `
           <p>Your wallet recharge is successful!</p>
            <p>Name:</p>
           <p>Your wallet recharge is successful!</p>
           <p>Payment Id:!</p>
            <i id="download-icon" class="fas fa-download" style="font-size: 24px; cursor: pointer;"></i>
                  `,
      // iconHtml: '<i class="fas fa-download"></i>', // Include the download icon
      confirmButtonText: 'OK',
      showCancelButton: false,
    }).then((result) => {
      console.log(result, 'result');
      if (result.value) {

      }
    });


    const popup = Swal.getPopup();
    if (popup) {
      const downloadIcon = popup.querySelector('#download-icon');
      if (downloadIcon) {
        downloadIcon.addEventListener('click', () => {
          console.log('Download icon clicked');
          this.handleDownload(); // Call your download handler
        });
      }
    }


  }

  rechargeOperation(request: any) {
    this.paymentService.createpOrderId(request).subscribe((response: any) => {
      const options = {
        key: AppConfigData.Razorpay_KeyId, // Replace with your Razorpay Key ID
        amount: request.amount * 100, // Amount in paise
        currency: 'INR',
        name: 'Ripple',
        description: ' Wallet Recharge ',
        // order_id: 'OZf8qRyQDdzyOH',
        order_id: response.data.orderId, // This is a sample Order ID. Pass the `id` obtained in the previous step
        handler: (response: any) => {
          console.log(response, 'payment sucess response');
          // this.paymentAlertSucess();
          //this.sharedService.showMessageDialog('Success', 'Payment successful!', SweetAlertIcon.SUCCESS);
          this.walletForm.reset();

          this.loaderService.setLoading(true);
          this.OnPaymentSuccess(response);
          this.submitted = false
          //alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        prefill: {
          name: this.userName,
          // email: '',
          contact: this.userPhone
        },
        notes: {
          address: '',
          // convenience_charge: '0',
        },
        config: {
          display: {
            /*  blocks: {
               convenience_charge: {
                 name: "Convenience Charge",
                 amount: 0 // Ensure convenience charge is 0
               }
             }, */
            hide: [
              { method: "emi" },
              // { key: "convenience_charge" } // Hide convenience charge
            ]
          },
        },
        theme: {
          color: '#54bbf3'
        },
        modal: {
          escape: false,
          ondismiss: () => {
            console.log('orignal payment cancelled');
            this.OnPaymentFailure(response.data.orderId)
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog('Failure', 'payment cancelled', SweetAlertIcon.ERROR);
          }
        },
        /*  const successCallback = (paymentid: any) => {
           console.log(paymentid);
         },
   
         const failureCallback = (paymentid: any) => {
           console.log(paymentid);
         } */
      };

      const rzp1 = new Razorpay(options);
      rzp1.open();
    }, error => {
      console.log(error)

      this.loaderService.setLoading(false);
    });
  }


  async payNow() {
    this.loaderService.setLoading(true);
    this.submitted = true;
    let amount = this.walletForm.get('amount')?.value;
    let currentBalance = this.walletHistoryList?.currentBalance;
    // currentBalance = -1000
    if (this.walletForm.invalid) {
      this.loaderService.setLoading(false);
      return
    } else {

      let request = {
        amount: amount,
        ConsumerId: this.userId,
        ProjectId: this.projectId,
      }
      if (currentBalance < 0) {
        // Ensure the recharge amount is greater than or equal to the absolute value of the negative balance
        if (amount && amount >= Math.abs(currentBalance)) {
          // Proceed with the recharge operation
          // Add your logic here for recharge
          await this.rechargeOperation(request)

        } else {
          // Handle the case where the recharge amount is too small
          // You might want to show an error or validation message
          this.sharedService.showMessageDialog('Failure', 'Recharge amount must be greater than or equal to the negative Wallet balance.', SweetAlertIcon.ERROR);
          this.loaderService.setLoading(false);
          console.log('Recharge amount must be greater than or equal to the negative balance.');
        }
      } else {
        // Handle the case where the balance is positive or zero
        // Add your logic for positive balance
        this.rechargeOperation(request)
      }

    }
  }







  handleDownload() {
    // Your download logic here
    console.log('Download action triggered');

  }
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  OnPaymentSuccess(payment: any,) {
    let request = {
      consumerId: this.userId,
      projectId: this.projectId,
      paymentId: payment.razorpay_payment_id,
      orderId: payment.razorpay_order_id,
      //signature: payment.razorpay_signature
    }
    this.paymentService.getPaymentDetails(request).subscribe({
      next: (response: any) => {
        if (response.data && response.statusCode == 200) {

          let paymentData = { ...response.data };
          paymentData['paymentId'] = payment.razorpay_payment_id
          // let paymentDetails = response.data;
          // let paymentDetails.razorpay_payment_id = payment.razorpay_payment_id;
          this.loaderService.setLoading(false);

          this.paymentlaertModal = this.modalService.show(RpAlertsComponent, {
            initialState: { paymentData },
            ...this.config,
          });


          /* this.paymentlaertModal = this.modalService.show(template, {
            class: 'modal-dialog-centered',
          }); */
          // this.sharedService.showMessageDialog('Success', 'Payment successful!', SweetAlertIcon.SUCCESS);
          // this.paymentAlertSucess(response.data);
          this.walletHistoryList = { ...this.walletHistoryList, lastRechargeAmount: response.data.lastRechargeAmount }
          this.walletHistoryList = { ...this.walletHistoryList, lastRechargeDate: response.data.lastRechargeDate }
          this.walletHistoryList = { ...this.walletHistoryList, currentBalance: response.data.currentBalance }
        }
      }, error: (error) => {
        this.sharedService.showMessageDialog(
          error.error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.log(error)
        this.loaderService.setLoading(false);
      }
    })

  }

  OnPaymentFailure(orderId: any) {
    let request = {
      OrderId: orderId
    }
    this.paymentService.paymentWalletFailure(request).subscribe((response: any) => { })

  }
  setAmount(amount: any) {
    this.walletForm.setValue({ amount: (amount) || '' })
  }





  /* getFormattedNumber() {
    const value = this.walletForm.get('amount')?.value;
    this.walletForm.patchValue({
      amount: this.decimalPipe.transform(value, '1.2-2')
    })
    
  
  } */

  onInputChange(event: any) {

    let newValue = event.target.value.replace(/[^0-9.]/g, '');
    // If you want to limit decimal places to two, you can uncomment the line below
    // newValue = this.limitDecimalPlaces(newValue, 2);
    this.walletForm.patchValue({ amount: newValue }, { emitEvent: false });
  }


}



