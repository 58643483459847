<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title"
            *ngIf="pageData?.isFlag !== 'consumersBulkupload' && pageData?.isFlag !== 'metersBulkupload'">
            Bulk Upload
        </h5>

        <h5 class="modal-title" *ngIf="pageData?.isFlag === 'consumersBulkupload'">
            Consumers Bulk Upload
        </h5>

        <h5 class="modal-title" *ngIf="pageData?.isFlag === 'metersBulkupload'">
            Meters Bulk Upload
        </h5>


        <button type="button" class="btn-close text-white" aria-label="Close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="mb-3">
                <button type="button" class="btn btn-light btn-label waves-light" (click)="downloadTemplate()"><i
                        class="mdi mdi-download  label-icon "></i> Download
                    Template</button>
            </div>
            <label>Upload Template</label>
            <kendo-upload [saveUrl]="uploadSaveUrl" (select)="handleFileSelected($event)" [removeUrl]="uploadRemoveUrl"
                [batch]="true" (success)="onUploadSuccess($event)" (error)="onUploadError($event)"
                [restrictions]="myRestrictions" [multiple]="false" accept=".doc,.docx,.xls,.xlsx">
            </kendo-upload>

        </form>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light" [disabled]="disableFile"
            (click)="uploadFile()">Upload</button>
    </div>
</div>