<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18"> Welcome! Dashboard</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card mini-stats-wid mini-stats-wid-border" *ngIf="isWalletEnabled">
                <div class="d-flex">
                    <div class="d-flex px-3 py-2 col-lg-6 my-2"
                        [ngClass]="(isWalletEnabled && billingType === 'Postpaid') ? 'col-lg-6' : (isWalletEnabled ? 'col-lg-12' : 'col-lg-6')">
                        <div class="mr-3 cursor-pointer" (click)="onWalletClick()">
                            <p class="mb-0 mb-2 font-size-18">Wallet Balance</p>
                            <h4 class="font-weight-bold">
                                {{consumergraphdata?.currentWalletBalance!=null?(consumergraphdata.currentWalletBalance
                                |currency:'INR':true):'0'}}
                            </h4>
                        </div>
                        <div class=" ms-auto d-flex justify-content-center align-items-center">
                            <div class="">
                                <img src="../../../assets/images/brands/rupeemorenew.png" class="w-80">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex px-3 py-2 col-lg-6 rp-border-right my-2" *ngIf="billingType=='Postpaid'"
                        [ngClass]="(!isWalletEnabled && billingType === 'Postpaid') ? 'col-lg-12' : ''">
                        <div class="mr-3 cursor-pointer" (click)="onBillsClick('paid')">
                            <p class="mb-0 mb-2 font-size-18">Unpaid Bills Amount</p>
                            <h4 class="font-weight-bold">
                                {{totalUnpaidBillsAmount!=null?(totalUnpaidBillsAmount
                                |currency:'INR':true):'0'}}
                            </h4>
                            <span class="font-size-10 float-end">{{lastPaidBillDate}}</span>
                            <!--  <p>{{viewBilldeatils.lastBillGeneratedDate!=null?
                                (viewBilldeatils.lastBillGeneratedDate |
                                date:'dd MMM yyyy hh:mm a'
                                )
                                :'' }}
                            </p> -->


                        </div>
                        <div class=" ms-auto d-flex justify-content-center align-items-center">
                            <div class="">
                                <img src="../../../assets/images/brands/stamp.png" class="w-80">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-lg-6" *ngIf="isWalletEnabled ==true"
                    [ngClass]="(isWalletEnabled && billingType === 'Postpaid') ? 'col-lg-6' : (isWalletEnabled ? 'col-lg-6' : 'col-lg-6')">
                    <div class="card">
                        <div class="card-body p-3">
                            <h4 class="card-title mb-1">Last 5 recharges </h4>
                            <div *ngIf="last5Recharges &&  last5Recharges.length>0">
                                <kendo-chart (seriesClick)="onRechargesClick($event)"
                                    [ngClass]="(isWalletEnabled && billingType === 'Postpaid') ? 'h-275' : (isWalletEnabled ? 'h-290' : 'h-275')">

                                    <kendo-chart-value-axis>
                                        <kendo-chart-value-axis-item [labels]="{ format: '₹ #.##' }">
                                            <!-- Rupee symbol for y-axis -->
                                        </kendo-chart-value-axis-item>
                                    </kendo-chart-value-axis>

                                    <kendo-chart-series>
                                        <kendo-chart-series-item *ngFor="let series of last5Recharges"
                                            [data]="series.data" categoryField="category" field="value"
                                            [colorField]="'colorString'" [stack]="true">
                                        </kendo-chart-series-item>
                                    </kendo-chart-series>

                                    <kendo-chart-tooltip format="₹{0}">
                                        <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                                            <div>
                                                <strong>{{ tooltip.series.name }}</strong><br>
                                                Month: {{ tooltip.category }}<br>
                                                Value: {{ tooltip.value ? ('₹ ' + tooltip.value.toFixed(2)) : '' }}
                                                <!-- Rupee symbol for tooltip -->
                                            </div>
                                        </ng-template>
                                    </kendo-chart-tooltip>
                                </kendo-chart>


                            </div>
                            <div *ngIf=" last5Recharges && last5Recharges.length==0"
                                class="bills-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
                                <p>No data available</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6" *ngIf="billingType=='Postpaid'">
                    <div class="card">
                        <div class="card-body p-3">
                            <h4 class="card-title mb-1">Last 5 bills</h4>
                            <div *ngIf="last5bills && last5bills.length>0">

                                <kendo-chart style="height: 275px;" (seriesClick)="onBillsClick('bills')">
                                    <kendo-chart-value-axis>
                                        <kendo-chart-value-axis-item [labels]="{ format: '₹ #.##' }">
                                            <!-- Rupee symbol for y-axis -->
                                        </kendo-chart-value-axis-item>
                                    </kendo-chart-value-axis>


                                    <kendo-chart-series>
                                        <kendo-chart-series-item *ngFor="let series of last5bills" [data]="series.data"
                                            categoryField="category" field="value" [colorField]="'colorString'"
                                            [stack]="true">
                                        </kendo-chart-series-item>
                                    </kendo-chart-series>

                                    <kendo-chart-tooltip format="₹{0}">
                                        <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                                            <div>
                                                <strong>{{ tooltip.series.name }}</strong>
                                                Month: {{ tooltip.category }}
                                                Value: {{ tooltip.value ? (tooltip.value |currency:'INR':true) : '' }}
                                            </div>
                                        </ng-template>
                                    </kendo-chart-tooltip>
                                </kendo-chart>
                            </div>
                            <div *ngIf="last5bills && last5bills.length==0"
                                class="bills-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
                                <p>No data available</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" *ngIf="billingType !=='Postpaid'">
                    <div class="card">
                        <div class="card-body p-3 ">
                            <h4 class="card-title mb-1">Last 5 deductions </h4>
                            <div *ngIf=" last5deductionsPayments && last5deductionsPayments.length>0">
                                <kendo-chart (seriesClick)="onDeductionsClick('deductions')"
                                    [ngClass]="(isWalletEnabled && billingType === 'Prepaid') ? 'h-290' : (isWalletEnabled ? 'h-290' : 'h-290')">
                                    <kendo-chart-value-axis>
                                        <kendo-chart-value-axis-item [labels]="{ format: '₹ #.##' }">
                                            <!-- Rupee symbol for y-axis -->
                                        </kendo-chart-value-axis-item>
                                    </kendo-chart-value-axis>

                                    <kendo-chart-series>
                                        <kendo-chart-series-item *ngFor="let series of last5deductionsPayments"
                                            [data]="series.data" categoryField="category" field="value"
                                            [colorField]="'colorString'" [stack]="true">
                                        </kendo-chart-series-item>
                                    </kendo-chart-series>

                                    <kendo-chart-tooltip format=" ₹{0}">
                                        <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                                            <div>
                                                <strong>{{ tooltip.series.name }}</strong>
                                                Month: {{ tooltip.category }}
                                                Value: {{ tooltip.value ? (tooltip.value |currency:'INR':true) : '' }}
                                            </div>
                                        </ng-template>
                                    </kendo-chart-tooltip>



                                    <!-- <kendo-chart-series>
                                    <kendo-chart-series-item [data]="last5deductionsPayments" field="value"
                                        categoryField="category" [labels]="{ visible: true, content: labelContent }"
                                        [colorField]="'colorString'">
                                    </kendo-chart-series-item>
                                </kendo-chart-series> -->
                                </kendo-chart>
                            </div>
                            <div *ngIf="last5deductionsPayments &&  last5deductionsPayments.length==0"
                                class="bills-no-data text-center d-flex justify-content-center align-items-center mb-2 superadminGraph">
                                <p>No data available</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div class="col-lg-6">
            <div class="card" [ngClass]="">
                <div class="card-body p-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h4 class="card-title"> Connection Status</h4>
                            <!--  <span class="text-muted font-size-12">No of connections</span> -->
                        </div>
                        <!-- <button class="btn btn-outline-secondary">More Details</button> -->
                    </div>

                    <div class="row mt-4">
                        <div class="col-xl-4" *ngFor="let item of consumerUtilityConnectionData">
                            <div class="card px-2 py-2 cursor-pointer" [ngClass]="{
                                'bg-electricity-subtle': item.utilityMasterName == 'Electricity', 
                                'bg-gas-subtle': item.utilityMasterName == 'Gas', 
                                'bg-water-subtle': item.utilityMasterName == 'Water',
                                'bg-ev-subtle': item.utilityMasterName == 'Ev'
                                
                                }" (click)="onUtilityClick(item.utilityMasterName) ">
                                <!-- {{item.utilityMasterName }} -->
                                <div>
                                    <img [src]="'../../../assets/images/brands/admindashboardutilityimages/' + item.utilityMasterName + '.svg'"
                                        class="rp-icon">
                                    <!--  <img [src]="../../../assets/images/brands/{{item.utilityMasterName}}.svg"
                                        class="rp-icon"> -->
                                </div>
                                <h4 class="mt-2">{{item.utilityMasterName}}</h4>
                                <p class="mb-1">
                                    {{ item.connectionsCount }} {{ item.connectionsCount > 1 ? 'Connections' :
                                    'Connection' }}
                                </p>


                                <p class="font-size-10 mb-0">
                                    <span *ngIf="item.consumptionPercentage !=0"
                                        [innerHTML]="getIcon(item.consumptionPercentage)"></span>
                                    <!-- <i class="fas fa-arrow-up text-danger"></i>
                                    <i class="fas fa-arrow-up text-danger"></i> -->
                                    <span class="text-primary" *ngIf="item.yesterdayConsumption == 'Consumption'">
                                        {{ item.consumptionPercentage != 0 ?
                                        getAbsoluteConsumption(item.consumptionPercentage).toFixed(2) :
                                        item.consumptionPercentage }}
                                        <span>%</span>
                                        {{ billingType == 'Postpaid' ? 'for last Month' : 'for yesterday' }}
                                    </span>
                                    <span class="text-primary" *ngIf="item.yesterdayConsumption=='No Consumption'">
                                        {{ billingType == 'Postpaid' ? ' No Consumption for yesterday' :
                                        'No Consumption for yesterday'

                                        }}

                                    </span>
                                </p>
                            </div>
                        </div>



                    </div>

                    <div class="row ">
                        <h4 class="card-title mb-2"> Manage Connections</h4>
                        <div class="managCard" *ngIf="electricityConnections &&  electricityConnections.length>0">
                            <div class="col mx-3" *ngFor="let item of electricityConnections">
                                <div class="row">
                                    <div class="card bg-subtle px-2 py-2" [ngStyle]="{
                                        'background-color': utility === 'Gas' ? '#FFFBD4' : utility === 'Water' ? '#DFEDFF' : '#D7FFE3'
                                      }">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="text-muted font-size-12 mb-0 " for=""
                                                [hidden]="utility === 'Water'">Meter On/Off :</label>
                                            <!-- <p class="" [hidden]="utility === 'Water'">Meter On/Off :</p> -->
                                            <div class="d-flex flex-column align-items-center justify-content-center"
                                                [hidden]="utility === 'Water'">

                                                <!-- Display Valve/Relay status with smaller font size -->
                                                <span class="status-text mb-1"
                                                    [ngClass]="{'text-success': item.valveOrRelayStatus, 'text-danger': !item.valveOrRelayStatus}"
                                                    style="font-size: 0.875rem;">
                                                    {{ item.valveOrRelayStatus ? 'ON' : 'OFF' }}
                                                </span>

                                                <!-- Display Meter On/Off action link with styled button-like appearance -->
                                                <div *ngIf="isMetermenu">
                                                    <div class="mt-1">
                                                        <a (click)="toggleMeterStatus(item.valveOrRelayStatus, item)"
                                                            *ngIf="(item.onRequest === 'No' && item.offRequest === 'No') ||
                                                    (item.onRequest === '' && item.offRequest === '')"
                                                            class="meter-link btn btn-sm"
                                                            [ngClass]="item.valveOrRelayStatus ? 'btn-outline-danger' : 'btn-outline-success'"
                                                            style="font-size: 0.8rem; text-transform: uppercase; padding: 0.2rem 0.6rem;">
                                                            {{ item.valveOrRelayStatus ? 'Turn Off' : 'Turn On'
                                                            }}
                                                        </a>
                                                    </div>

                                                </div>



                                            </div>
                                            <!--  <div>
                                            <ui-switch color="#22C55E" [checked]="item.isActive" uncheckedLabel="Off"
                                                checkedLabel="On" size="small" class="me-1"></ui-switch>
                                        </div> -->
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="text-muted font-size-12" for="">Device Sr No:</label>
                                            <p class="mb-0 font-size-12"> <b>{{item.meterSerialNo}}</b></p>

                                        </div>

                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="text-muted font-size-12" for="">Location:</label>
                                            <p class="font-weight-bolder mt-2 mb-2 font-size-12">
                                                <b> {{item.installedLocation}} </b>
                                            </p>

                                        </div>
                                        <hr class="hr">
                                        <div *ngIf="utility.toUpperCase() !==  'ELECTRICITY' "
                                            class="d-flex justify-content-between align-items-center ">
                                            <label for="" class="text-muted font-size-12 mb-0">Unbilled
                                                consumption:</label>
                                            <p class="font-weight-bolder mt-2 mb-2 font-size-12"><b> {{ utility ==
                                                    'Water'
                                                    ? item.unbilledConsumption +
                                                    'L ' : utility == 'Gas' ?
                                                    item.unbilledConsumption + '
                                                    m³': ''}}</b> </p>
                                        </div>
                                        <div *ngIf="utility.toUpperCase() ==  'ELECTRICITY'"
                                            class="d-flex justify-content-between align-items-center  ">
                                            <label for="" class="text-muted font-size-12 ">Unbilled
                                                consumption EB:</label>
                                            <p class="font-weight-bolder font-size-12">
                                                <b>{{ utility == 'Electricity' ? item.unbilledConsumptionEB+ ' kWh' :
                                                    ''}}</b>

                                            </p>
                                        </div>
                                        <div *ngIf="utility.toUpperCase() ==  'ELECTRICITY'"
                                            class="d-flex justify-content-between align-items-center ">
                                            <label for="" class="text-muted font-size-12 mb-0">Unbilled
                                                consumption DG:</label>
                                            <p class="font-weight-bolder font-size-12">
                                                <b> {{ utility == 'Electricity' ? item.unbilledConsumptionDG+ ' kWh' :
                                                    ''}}</b>

                                            </p>
                                        </div>

                                        <div
                                            class="d-flex justify-content-between align-items-center mt-2 font-size-12 text-muted">
                                            <label for="" class="text-muted font-size-12 mb-0">Consumption
                                                Period:</label>
                                            <!--  <p class="mb-0 text-dark font-size-12" *ngIf="billingType !=='Prepaid'">
                                                {{item.dateRange }}
                                            </p> -->
                                            <p class="mb-0 text-dark font-size-12">
                                                <b> {{item?.startDateFormatted!=null?
                                                    (item?.startDateFormatted | date:'d MMM yyyy HH:mm')
                                                    :'' }} to Till Now</b>

                                            </p>

                                            <!--  <p class="mb-0 text-dark font-size-12">
                                                <b> {{item?.startDateFormatted!=null?
                                                    (item?.startDateFormatted | date:'d MMM yyyy HH:mm')
                                                    :'' }} to {{item?.endDateFormatted!=null?
                                                    (item?.endDateFormatted | date:'d MMM yyyy HH:mm')
                                                    :'' }}</b>

                                            </p> -->


                                        </div>
                                        <div
                                            class="d-flex justify-content-between align-items-center mt-2 font-size-12 text-muted">
                                            <label for="" class="text-muted font-size-12 mb-0">
                                                Last billed reading</label>

                                            <p class="mb-0 text-dark font-size-12">
                                                <b>{{utility == 'Electricity'? (item.lastBilledReading + ' kWh'):utility
                                                    == 'Water'?(item.lastBilledReading + ' L'):
                                                    utility == 'Gas'? (item.lastBilledReading + ' m³'):''}}</b>
                                            </p>
                                        </div>
                                        <div
                                            class="d-flex justify-content-between align-items-center mt-2 font-size-12 text-muted">
                                            <label for="" class="text-muted font-size-12 mb-0">Previous Bill
                                                :</label>
                                            <p class="mb-0 text-dark font-size-12">
                                                <b> {{item?.lastBiiledDate!=null?
                                                    (item?.lastBiiledDate | date:'d MMM yyyy HH:mm')
                                                    :'' }} </b>

                                            </p>
                                        </div>
                                        <div
                                            class="d-flex justify-content-between align-items-center mt-2 font-size-12 text-muted">
                                            <label for="" class="text-muted font-size-12 mb-0">
                                                Unbilled Days</label>

                                            <p class="mb-0 text-dark font-size-12">
                                                <b>{{item.noOfUnbilledDays}}</b>

                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="electricityConnections && electricityConnections.length==0"
                            class="bills-no-data text-center d-flex justify-content-center align-items-center superadminGraph my-3">
                            <p>No data available</p>
                        </div>



                    </div>
                </div>

            </div>

        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body p-3" style="width: 100%; overflow: hidden">
                    <h4 class="card-title me-3">Consumption Details </h4>
                    <div class="d-flex  justify-content-end">

                        <div class="d-flex justify-content-end  ">
                            <!-- <div class=" me-2 flex-grow-1 input-container" *ngIf="dateRange">
                                <input *ngIf="dateRange" type="text" class="form-control date"
                                    placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                                    [bsConfig]="datePickerConfig" (bsValueChange)="onDateRangeSelected($event)" />
                            </div> -->
                            <div class="position-relative flex-grow-1 me-2 " *ngIf="dateRange">
                                <input type="text" class="form-control date " style="width: 238px;"
                                    [value]="filterProjectBillDates ? (formatDateRange(filterProjectBillDates)) : ''"
                                    placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                                    [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"
                                    (bsValueChange)="onDateRangeSelected($event)" />
                                <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates"
                                    (click)="clearDate()"></span>
                            </div>

                            <div class=" flex-grow-1  me-2">
                                <ng-select [items]="months" [clearable]="false" (change)="selectionChange($event)"
                                    placeholder="Select Month" id="billingType" [(ngModel)]="selectedRange"
                                    [multiple]="false" [deselectOnClick]="false"></ng-select>
                            </div>
                            <div class=" flex-grow-1 ">
                                <ng-select [items]="projectUtlityList" [clearable]="false"
                                    [(ngModel)]="consuptionSelectedUtility"
                                    (change)="consuprtionUtilitiesChange($event)" bindValue="id" bindLabel="name"
                                    [multiple]=" false" [deselectOnClick]="false"></ng-select>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="consumptionData &&   consumptionData.length>0">
                        <apx-chart #chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [colors]="chartOptions.colors" [yaxis]="chartOptions.yaxis"
                            [dataLabels]="chartOptions.dataLabels" [markers]="chartOptions.markers"
                            [stroke]="chartOptions.stroke" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
                            [annotations]="chartOptions.annotations"></apx-chart>



                        <div class="d-flex justify-content-end text-danger">
                            <span>*{{selectedUtilityName?.name =='Water'?
                                selectedUtilityName?.name +
                                ' in Liters':
                                selectedUtilityName?.name =='Electricity'? selectedUtilityName?.name +
                                ' in kWh':

                                selectedUtilityName?.name +' in m³'}} </span>
                            <!-- <span>* {{selectedUtility}} units in liters </span> -->
                        </div>

                    </div>
                    <div *ngIf=" consumptionData &&consumptionData.length==0"
                        class="bills-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
                        <p>No data available</p>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>