<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <!--   <h4 class="mb-0 font-size-18">Meters Details</h4> -->
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll_2()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>
                <!-- <span class="breadcrumb m-0">
                    @if(cdkStepper.selectedIndex === 0){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}

                    @if(projectBillingType!='Postpaid' ){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAllConsumptions()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    @if(this.currentUser?.currentUserRoleDetails?.role == roles.ADMIN){ <button
                        class="btn btn-primary d-flex align-items-center" (click)="exportAllMeterOnOff()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                </span> -->

            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="onStepChange($event)">

                        <cdk-step [optional]=" false">
                            <ng-template cdkStepLabel>
                                <span class="number">1</span>
                                <span>Meter Readings</span>
                            </ng-template>
                            @if(cdkStepper.selectedIndex === 0){

                            <section class="">

                                <div class="row mb-2 d-sm-block d-md-none">
                                    <h4 class="card-title mb-4 text-decoration-underline">Meter Readings</h4>
                                </div>

                                <section>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="mb-3">
                                                            <label class="form-label">From Date -To Date </label>
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control date"
                                                                    [value]="fromDate ? (formatDateRange(fromDate)) : ''"
                                                                    placeholder="Select Date" name="daterange"
                                                                    autocomplete="off" bsDaterangepicker
                                                                    [bsConfig]="datePickerConfig"
                                                                    [(bsValue)]="fromDate" />
                                                                <span class="bx bx-calendar inputIcon"
                                                                    (click)="openCalendar()"></span>
                                                                <!-- Clear icon -->
                                                                <span class="bx bx-x inputIcon clear-icon"
                                                                    *ngIf="fromDate.length>0"
                                                                    (click)="clearDate()"></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 d-flex align-items-center">
                                                        <button class="btn btn-primary btn-md mt-2"
                                                            (click)="filterBasedOnDate(1)">Filter</button>
                                                        <button class="btn btn-secondary btn-md mt-2 ms-2"
                                                            (click)="filterBasedOnDate(0)">Clear</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </section>

                                <section class="mt-3">
                                    <app-rp-meters-reading></app-rp-meters-reading>
                                </section>

                            </section>


                            }
                        </cdk-step>

                        <cdk-step [optional]="true" *ngIf="projectBillingType!='Postpaid'">
                            <ng-template cdkStepLabel>
                                <span class="number">2</span>
                                <span>Consumption History</span>
                            </ng-template>
                            <!--   @if(cdkStepper.selectedIndex === 1 ){ -->
                            <section>
                                <div class="row mb-4 d-sm-block d-md-none">
                                    <h4 class="card-title mb-4 text-decoration-underline">Consumption History</h4>
                                </div>
                                <ng-template #template let-anchor>
                                    <span>{{ anchor.nativeElement.innerText }}</span>
                                </ng-template>
                                <app-rp-consumption-history [meterViewDetails]="meterViewDetails"
                                    (meterInfo)="handleMeterInfo($event)">
                                </app-rp-consumption-history>

                                <!--supplier download buttons end-->

                                <ul class="list-inline wizard d-flex justify-content-end mt-4">
                                    <li class="previous list-inline-item" aria-disabled="true"><button
                                            class="btn btn-outline-primary" cdkStepperPrevious>Previous</button></li>
                                </ul>

                            </section>
                            <!--   } -->
                        </cdk-step>


                        <cdk-step [optional]="true"
                            *ngIf="this.currentUser?.currentUserRoleDetails?.role == roles.ADMIN">
                            <ng-template cdkStepLabel>
                                <span class="number">3</span>
                                <span>Meter On/Off History </span>
                            </ng-template>
                            <!--  @if(cdkStepper.selectedIndex === 2 ){ -->
                            <section>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">From Date -To Date </label>
                                                        <div class="position-relative">
                                                            <input type="text" class="form-control date"
                                                                [value]="meterOnOfffromDate ? (formatDateRange(meterOnOfffromDate)) : ''"
                                                                placeholder="Select Date" name="daterange"
                                                                autocomplete="off" bsDaterangepicker
                                                                [bsConfig]="datePickerConfig"
                                                                [(bsValue)]="meterOnOfffromDate" />
                                                            <span class="bx bx-calendar inputIcon"
                                                                (click)="meterOnOffopenCalendar()"></span>
                                                            <!-- Clear icon -->
                                                            <span class="bx bx-x inputIcon clear-icon"
                                                                *ngIf="meterOnOfffromDate.length>0"
                                                                (click)="clearDate()"></span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-2 d-flex align-items-center">
                                                    <button class="btn btn-primary btn-md mt-2"
                                                        (click)="metreOnoFffilterBasedOnDate(1)">Filter</button>
                                                    <button class="btn btn-secondary btn-md mt-2 ms-2"
                                                        (click)="metreOnoFffilterBasedOnDate(0)">Clear</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>

                            </section>
                            <section class="mt-3">
                                <ng-template #template let-anchor>
                                    <span>{{ anchor.nativeElement.innerText }}</span>
                                </ng-template>
                                <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                    (mouseover)="showTooltip($event)">

                                    <kendo-grid [data]="MeterOnOffHostotrydData" [reorderable]="true" filterable="menu"
                                        [columnMenu]="{ filter: true }" [resizable]="true" [skip]="skip"
                                        [pageSize]="meteronOffpagesize" [pageable]="{
                                     buttonCount: buttonCount,
                                    info: info,
                                     type: type,
                                    pageSizes: meterOnOffHistoryPageSize,
                                    previousNext: previousNext
                                  }" [selectable]="selectableSettings" [filter]="meterOnOffControlsState.filter"
                                        [sort]="meterOnOffControlsState.sort" [columnMenu]="{ filter: true }"
                                        (filterChange)="onOffMetertFilterChange($event)"
                                        (sortChange)="onOffSortChange($event)" [sortable]="true" [resizable]="true"
                                        (selectionChange)="onOffSelect($event)"
                                        (pageChange)="meterOnOffPageChange($event)"
                                        (dataStateChange)="meterOnOffStateChange($event)">

                                        <ng-template kendoGridToolbarTemplate>
                                            <div class="search_icon">
                                                <kendo-textbox [style.width.px]="250"
                                                    [(ngModel)]="meteronOffsearchedValue" [clearButton]="true"
                                                    (valueChange)="MeterOnOffValueChange($event)"
                                                    (keydown.enter)="onSearchMeterOnOffUnits()" [style.height.px]="35"
                                                    [style.height.px]="35" [style.height.px]="35"
                                                    placeholder="Search in all columns...">
                                                </kendo-textbox>

                                                <button class="btn btn-primary btn-md border-left-none"
                                                    (click)="onFilterOnOff($event)">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                            <kendo-grid-spacer></kendo-grid-spacer>
                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="onMeterExport('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>

                                        </ng-template>

                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="selectedRowdataOnOff.length>0 ? selectedRowdataOnOff:exportData.length>0?exportData:MeterOnOffHostotrydData.data"
                                                #pdfGrid>
                                                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                    class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                                <kendo-grid-column field="meterSrNo" title="Device Sr No"
                                                    [width]="150"></kendo-grid-column>
                                                <kendo-grid-column field="macAddress" title="Mac Address"
                                                    [width]="150"></kendo-grid-column>
                                                <kendo-grid-column field="utilityType" title="Utility"
                                                    [width]="150"></kendo-grid-column>
                                                <kendo-grid-column field="occupant" title="Occupant"
                                                    [width]="150"></kendo-grid-column>
                                                <kendo-grid-column field="onOffStatus" title="Meter On/Off Status"
                                                    [width]="130"></kendo-grid-column>
                                                <kendo-grid-column field="Date" title="Date" [width]="130">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{dataItem.Date!==null?
                                                        ( dataItem.Date | date: 'dd MMM yyyy hh:mm a') :'-'}}

                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="MeterOnOff.xlsx"></kendo-grid-excel>
                                            </kendo-grid>
                                        </div>



                                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                            class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                        <kendo-grid-column field="meterSrNo" title="Device Sr No"
                                            [width]="150"></kendo-grid-column>
                                        <kendo-grid-column field="macAddress" title="Mac Address"
                                            [width]="150"></kendo-grid-column>
                                        <kendo-grid-column field="utilityType" title="Utility"
                                            [width]="150"></kendo-grid-column>
                                        <kendo-grid-column field="occupant" title="Occupant"
                                            [width]="150"></kendo-grid-column>
                                        <kendo-grid-column field="onOffStatus" title="Meter On/Off Status"
                                            [width]="130"></kendo-grid-column>
                                        <kendo-grid-column field="onOffDate" title="Date" [width]="130" filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.onOffDate!==null?
                                                ( dataItem.onOffDate | date: 'dd MMM yyyy hh:mm a') :'-'}}


                                            </ng-template>
                                        </kendo-grid-column>
                                    </kendo-grid>
                                </div>

                            </section>
                            <!--   } -->
                        </cdk-step>

                    </ng-stepper>

                </div>
            </div>
        </div>
    </div>

</div>