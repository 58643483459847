import { Injectable } from '@angular/core';
import { userListModel } from '../../../app/store/UserList/userlist.model';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ResidentialunitService {
  constructor(private http: HttpClient, private router: Router) { }

  getProjectUmds(id: any): Observable<any> {
    return this.http.get<any>(AppConfigData.getProjectUmdssUrl + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getResidentialUnit(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    id: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getResidentialUnitUrl + id}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getOccupationHistoryUnit(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    id: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getOccupationHstoryUrl + id
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;



    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getresidentialunitumdhistory(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    id: string,
    filter?: string,
    orderBy?: string,
    residentialunitid?: string,
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getresidentialunitumdhistoryUrl + id + '/' + residentialunitid
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    /*  let url;
     if (searchTerm) {
       url = `${AppConfigData.getresidentialunitumdhistoryUrl + id
         }?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getresidentialunitumdhistoryUrl + id
         }?pageIndex=${pageIndex}&pageSize=${pageSize}`;
     } */

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getResidentialUnitById(id: any): Observable<any> {
    return this.http
      .get<any>(AppConfigData.getResidentailUnitByIdUrl + id)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getMasters failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
  //
  updatetResidentialUnitById(residentilaUnit: any, id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateResidentailUnitdUrl + id, residentilaUnit)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getMasters failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  //Create  User
  createresidentilaUnit(residentilaUnit: any): Observable<any> {
    console.log('userData->', residentilaUnit);

    return this.http
      .post<any>(AppConfigData.createResidentialUnitUrl, residentilaUnit)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
}
