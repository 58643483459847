import { SharedService } from './../../shared/shared.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { url } from 'inspector';
import { LocalStorageService } from './localStorage.service';
import { LoaderService } from './loader.service';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  ServiceError = Validations.ServiceError;
  constructor(private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService, private SharedService: SharedService,
    private localStorageService: LocalStorageService) { }



  exportReports(payload: any) {
    const headers = new HttpHeaders({
      // 'No-Intercept': 'true', // Custom header to skip interceptor
    });

    // Build the URL
    let url = `${AppConfigData.exportReportsUrl}`;

    // Append ProjectIds if they exist and are not 'all'
    if (payload?.ProjectIds && payload?.ProjectIds.length) {
      url += payload?.ProjectIds !== 'all'
        ? `?${payload.ProjectIds.map((id: string) => `ProjectIds=${encodeURIComponent(id)}`).join('&')}`
        : '';
    }

    // Append BatteryStatus if it exists
    if (payload?.BatteryStatus) {
      url += `${url.includes('?') ? '&' : '?'}BatteryStatus=${encodeURIComponent(payload.BatteryStatus)}`;
    }

    // Append Utility if it exists
    if (payload?.Utility) {
      url += `${url.includes('?') ? '&' : '?'}Utility=${encodeURIComponent(payload.Utility)}`;
    }

    // Make the HTTP GET request
    this.http
      .get(url, {
        headers,
        responseType: 'blob', // We expect a binary response (blob)
      })
      .subscribe(
        (blob) => {
          this.loaderService.setLoading(false);
          // Create a link element for the file download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Reports'; // Set the download filename

          // Append the link to the body, click it, then remove it
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.SharedService.showMessageDialog(
            error.error.message,
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          this.loaderService.setLoading(false);
          console.error('Error downloading the report: ', error);
        }
      );
  }


  exportReport(payload: any, reportType: string) {
    let url: string;
    let fileName: string;

    // Determine the URL and filename based on the report type
    switch (reportType) {
      case 'BatteryStatus':
        url = AppConfigData.exportReportsUrl;
        fileName = 'Battery_Status_Report.xlsx';
        break;
      case 'activation':
        url = AppConfigData.getActivationStatusReportUrl;
        fileName = 'Activation_Status_Report.xlsx';
        break;
      case 'consumption':
        url = AppConfigData.getPrepaidConsumptionReportUrl;
        fileName = 'Consumption_Report.xlsx';
        break;
      case 'postpaid':
        url = AppConfigData.getPostpaidConsumptionReportUrl;
        fileName = 'Consumption_Postpaid_Report.xlsx';
        break;
      case 'DeviceCommunications':
        url = AppConfigData.getDeviceCommunicationnReportUrl;
        fileName = 'Device_Communication_Report.xlsx';
        break;
      case 'MeterReadings':
        url = AppConfigData.getMeterReadingReportUrl;
        fileName = 'Meter_Readings_Report.xlsx';
        break;
      case 'BillGeneration':
        url = AppConfigData.getBillGenerationReportUrl;
        fileName = 'Bill_Generation_Report.xlsx';
        break;
      case 'WalletBalance':
        url = AppConfigData.getWalletBlanceGenerationReportUrl;
        fileName = 'Wallet_Balance_Report.xlsx';
        break;
      case 'WalletHistory':
        url = AppConfigData.getWalletHistoryReportUrl;
        fileName = 'Wallet_History_Report.xlsx';
        break;
      case 'Settlment':
        url = AppConfigData.getsettlementReportUrl;
        fileName = 'Settlemnt_Report.xlsx';
        break;
      case 'monthwiseBalance':
        url = AppConfigData.getMonthlyBalanceReport;
        fileName = 'monthwiseBalance.xlsx';
        break;
      case 'daily Consumption':
        url = AppConfigData.getgetdailyconsumptionReport;
        fileName = 'daily_Consumption.xlsx';
        break;
      default:
        console.error('Invalid report type');
        return;
    }

    // Define the HTTP options for the request
    const httpOptions = {
      responseType: 'blob' as 'blob', // Specify the response type as 'blob'
      observe: 'response' as const    // Observe the entire response
    };

    // Make the HTTP POST request
    this.http.post(url, payload, httpOptions).subscribe(
      (response: any) => {
        this.loaderService.setLoading(false);

        // Check if the response is a JSON object with "No Data Found" message
        response.body.text().then((text: any) => {
          try {
            const jsonResponse = JSON.parse(text);
            if (jsonResponse && jsonResponse.message === 'No Data Found.') {
              this.SharedService.showMessageDialog(
                jsonResponse.message,
                '',
                SweetAlertIcon.INFO
              );
              return;
            }
          } catch {
            // Not JSON, proceed to download as blob
            const blob = new Blob([response.body], { type: 'application/octet-stream' });

            // Create a link element for the file download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // Set the download filename

            // Append the link to the body, click it, then remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Revoke the object URL after the download
            URL.revokeObjectURL(link.href);
          }
        });
      },
      (error) => {
        this.loaderService.setLoading(false);
        this.SharedService.showMessageDialog(
          error.error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.error('Error downloading the report:', error);
      }
    );
  }



  getFlatNumbersByProjectId(projectId: string): Observable<any> {
    return this.http
      .get<any>(AppConfigData.getFlatNumbers + projectId, {})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('feedbacklist  failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }





}
