import { Component, ViewChild } from '@angular/core';


import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { getmeterDeatailsResponse, updateConsumerMeterDetailssuccessResponse } from '../../../store/customer/consumer-selector';
import { getmeterDeatails, resetupdateConsumerMeterDetails, updateConsumerMeterDetails } from '../../../store/customer/consumer.action';
import { DragOverEvent, NavigateEvent, SortableComponent } from '@progress/kendo-angular-sortable';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-rp-meter-details',
  templateUrl: './rp-meter-details.component.html',
  styleUrl: './rp-meter-details.component.scss'
})
export class RpMeterDetailsComponent {
  @ViewChild('sortable') public sortable!: SortableComponent;
  isAllOpen: boolean = true;
  meterDetails: any;
  water: string = '';
  gas: string = '';
  currentUser: any;
  residentialUnitId: any
  consumerId: any
  electricity: string = '';
  sortBy: any[] = []
  breadCrumbItems: Array<{}> | undefined;

  private updatemerterdetailssubscription: Subscription = new Subscription();
  modifiedInputFields: any[] = []
  public inputFields = [
    { label: 'Flat/House#', name: 'Flat_House', value: '' },
    { label: 'Floor', name: 'Floor', value: '' },
    { label: 'Tower', name: 'Tower', value: '' },
    { label: 'Block', name: 'Block', value: '' },
  ];
  constructor(private router: Router,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService
  ) {
    this.breadCrumbItems = [{ label: 'Meter Details' }];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;

    this.getBillsStore()
  }

  ngOnInit(): void {
    this.store.dispatch(getmeterDeatails());
  }

  public onDragOver(event: DragOverEvent): void {
    event.preventDefault();
    this.sortable.moveItem(event.oldIndex, event.index);
    this.isButtonEnabled = true
  }

  getBillsStore() {
    this.store.dispatch(resetupdateConsumerMeterDetails());

    let getmeterDeatailsResponse$ = this.store.pipe(select(getmeterDeatailsResponse));

    this.updatemerterdetailssubscription = this.store.pipe(select(updateConsumerMeterDetailssuccessResponse)).subscribe({
      next: (res: any) => {
        console.log('res', res);
        if (res) {

          this.inputFields.forEach((field) => {
            if (field.name == 'Flat_House') {
              field.value = res.data.flatNo;
            }
            if (field.name == 'Floor') {
              field.value = res.data.floor;
            }
            if (field.name == 'Tower') {
              field.value = res.data.tower;
            }
            if (field.name == 'Block') {
              field.value = res.data.block;
            }
          });
          console.log(this.inputFields)
          if (res && res.data && res.data.sortOrder !== '') {
            //console.log('sortOrder:', res.data.sortOrder);  // Accessing res.data.sortOrder
            const sortOrderString = res.data.sortOrder.split(',');  // Split the sortOrder

            this.inputFields.sort((a, b) => {
              // Check if both a.name and b.name exist in sortOrderString
              const aIndex = sortOrderString.indexOf(a.name);
              const bIndex = sortOrderString.indexOf(b.name);

              // If either of them is not found, it will return -1, but if both are not found, they are placed at the end
              return aIndex === -1 ? (bIndex === -1 ? 0 : 1) : (bIndex === -1 ? -1 : aIndex - bIndex);
            });
            this.modifiedInputFields = this.inputFields
            // console.log(this.inputFields);
            // console.log(this.modifiedInputFields);
          }



          this.sharedService.showMessageDialog(
            'Success',
            res.message,
            SweetAlertIcon.SUCCESS
          );
        }
      }
    })


    getmeterDeatailsResponse$.subscribe((res: any) => {
      console.log('res', res);
      if (res) {
        this.meterDetails = res;
        this.residentialUnitId = res.id;
        this.water = this.meterDetails.water.length > 0 ? `Water ( ${this.meterDetails.water.length})` : ''
        this.gas = this.meterDetails.gas.length > 0 ? `Gas ( ${this.meterDetails.gas.length})` : ''
        this.electricity = this.meterDetails.electricity.length > 0 ? `Electricity ( ${this.meterDetails.electricity.length})` : ''

        //this.inputFields = []
        this.inputFields = [
          { label: 'Flat/House#', name: 'Flat_House', value: '' },
          { label: 'Floor', name: 'Floor', value: '' },
          { label: 'Tower', name: 'Tower', value: '' },
          { label: 'Block', name: 'Block', value: '' },
        ];
        this.inputFields.forEach((field) => {
          if (field.name == 'Flat_House') {
            field.value = res.flatNo;
          }
          if (field.name == 'Floor') {
            field.value = res.floor;
          }
          if (field.name == 'Tower') {
            field.value = res.tower;
          }
          if (field.name == 'Block') {
            field.value = res.block;
          }
        });
        // console.log('sortOrder:', this.inputFields);
        if (res && res.sortOrder !== '') {
          // console.log('sortOrder:', res.sortOrder);
          const sortOrderString = res.sortOrder.split(',');
          this.inputFields.sort((a, b) => {
            // Check if both a.name and b.name exist in sortOrderString
            const aIndex = sortOrderString.indexOf(a.name);
            const bIndex = sortOrderString.indexOf(b.name);

            // If either of them is not found, it will return -1, but if both are not found, they are placed at the end
            return aIndex === -1 ? (bIndex === -1 ? 0 : 1) : (bIndex === -1 ? -1 : aIndex - bIndex);
          });

          this.modifiedInputFields = this.inputFields
        } else {
          this.modifiedInputFields = this.inputFields
        }
        //console.log(this.modifiedInputFields)
        //console.log(this.inputFields)

      } else {
        this.meterDetails = {}
        console.log('res', res);
      }
    })
  }


  ngOnDestroy() {
    this.updatemerterdetailssubscription.unsubscribe();
  }


  public onNavigate(event: NavigateEvent): void {
    this.sortable.moveItem(event.oldIndex, event.index);
  }
  public isButtonEnabled: boolean = false;

  updateMeters() {
    this.sortBy = []
    this.inputFields.forEach((field: any) => {
      this.sortBy.push(field.name);
    })
    console.log(this.sortBy);
    let request = {
      consumerId: this.consumerId,
      sortOrder: this.sortBy.toString(),
      ResidentialUnitId: this.meterDetails.id

    }
    this.store.dispatch(updateConsumerMeterDetails({ updaedMeterData: request }));

  }



}
