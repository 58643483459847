<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>

        </span>

      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="page-title-right">
            <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>

          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [data]="residentialunitgridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
              [selectable]="selectableSettings" [pageable]="{
                                            buttonCount: buttonCount,
                                            info: info,
                                            type: type,
                                            pageSizes: pageSizes,
                                            previousNext: previousNext
                                          }" [reorderable]="true" filterable="menu" [filter]="residentialState.filter"
              [sort]="residentialState.sort" [columnMenu]="{ filter: true }"
              (filterChange)="onresidentialFilterChange($event)" (sortChange)="onSortChange($event)" [resizable]="true"
              scrollable="scrollable" (pageChange)="residentialPageChange($event)" (selectionChange)="onSelect($event)"
              (dataStateChange)="residentialStateChange($event)">

              <ng-template kendoGridToolbarTemplate>
                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                  [(ngModel)]="searchedResidentialunit" (valueChange)="onFilter($event)"></kendo-textbox> -->
                <div class="search_icon">
                  <kendo-textbox [style.width.px]="250" [clearButton]="true" (valueChange)="onValueChange($event)"
                    (keydown.enter)="onSearchResidentialUnits()" [(ngModel)]="searchedResidentialunit"
                    placeholder="Search in all columns...">
                  </kendo-textbox>

                  <button class="btn btn-primary border-left-none " (click)="onFilter($event)">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUploadMeters()">Add Bulk Meters
                </button>
                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUploadConsumers()">Add Bulk consumers
                </button>
                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUpload()">Bulk Upload </button>
                <button class="btn btn-outline-primary btn-sm"
                  [routerLink]="'/residentialUnit/createResidentialUnit'">Add Residential Unit </button>


                <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="onExport('Excel')">
                  <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                </button>

                <!-- <button  class="btn btn-light" (click)="onExport('Pdf')">
                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

              </ng-template>
              <!-- Pdf   start-->
              <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                <kendo-grid [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:residentialunitgridData.data"
                  #pdfGrid>
                  <kendo-grid-column field="flatNo" title="Flat / House No"></kendo-grid-column>
                  <kendo-grid-column field="floor" title="Floor"></kendo-grid-column>
                  <kendo-grid-column field="tower" title="Tower"></kendo-grid-column>
                  <kendo-grid-column field="block" title="Block"></kendo-grid-column>
                  <kendo-grid-column field="occupant" title="occupant">

                  </kendo-grid-column>
                  <kendo-grid-column field="currentOccupiedPerson" title="Current Occupied Person"></kendo-grid-column>
                  <!-- <kendo-excelexport-column field="occupant" title="Occupant">
                  </kendo-excelexport-column> -->
                  <!-- <kendo-grid-column field="residentialUnit"
          title="Occupied Residential Unit"></kendo-grid-column> -->


                  <kendo-grid-excel fileName="residentialUnit.xlsx"></kendo-grid-excel>
                </kendo-grid>
              </div>
              <!-- Pdf Edrt-->

              <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                class="no-tooltip"></kendo-grid-checkbox-column>
              <kendo-grid-column field="flatNo" title="Flat / House No" [width]="200"></kendo-grid-column>
              <kendo-grid-column field="floor" title="Floor" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="tower" title="Tower" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="block" title="Block" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="occupant" title="Occupant" [width]="180">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.occupant !=''? dataItem.occupant:'-'}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="currentOccupiedPerson" title="Current Occupied Person" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.currentOccupiedPerson !=''? dataItem.currentOccupiedPerson:'-'}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-command-column title="" [columnMenu]="false" [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <!-- Edit Residential Unit Icon -->
                  <span kendoTooltip title="Go to Residential Unit Edit" (click)="residentilaunitEdit(dataItem)"
                    style="cursor: pointer;">
                    <i class="fas fa-edit text-primary"></i>
                  </span>
                </ng-template>
              </kendo-grid-command-column>



            </kendo-grid>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Bulk upload modal -->
<div bsModal #bulkUploadModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Bulk Upload</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
          (click)="bulkUploadModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal">

          <div class="mb-3">
            <button type="button" class="btn btn-light btn-label waves-light"><i
                class="mdi mdi-download  label-icon "></i> Download Template</button>
          </div>


          <label>Upload Template</label>
          <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" [batch]="true">
          </kendo-upload>


        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Upload</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Bulk upload modal End  -->