<div class="container-fluid">
    <!--  <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div> -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="settlementData" [reorderable]="true" filterable="menu"
                            [columnMenu]="{ filter: true }" scrollable="scrollable" [resizable]="true"
                            [pageSize]="pageSize" [pageable]="{
                              buttonCount: buttonCount,
                              info: info,
                              type: type,
                              pageSizes: pageSizes,
                              previousNext: previousNext
                            }" [selectable]="selectableSettings" [filter]="settlmentState.filter" [sortable]="true"
                            [sort]="settlmentState.sort" [skip]="skip" [sort]="settlmentState.sort"
                            (filterChange)="onsetellmentFilterChange($event)" (sortChange)="onSortChange($event)"
                            (selectionChange)="onSelect($event)" (pageChange)="settlmentPageChange($event)"
                            (dataStateChange)="settlmentStateChange($event)">
                            <ng-template kendoGridToolbarTemplate>
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" (keyup.enter)="onSearchPayments()"
                                        [style.height.px]="35" [(ngModel)]="searchedValue"
                                        placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                                <kendo-grid-spacer></kendo-grid-spacer>

                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                    (click)="onExport('Excel')">
                                    <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                                </button>
                            </ng-template>

                            <!-- Hidden grid for PDF/Excel export -->
                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                <kendo-grid
                                    [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata : settlementData.data"
                                    #pdfGrid>
                                    <!-- <kendo-grid [kendoGridBinding]="
                                        selectedRowdata.length > 0
                                            ? selectedRowdata
                                            : exportData.length > 0
                                            ? exportData
                                            : settlementData.data
                                    " #pdfGrid> -->
                                    <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                        class="no-tooltip"></kendo-grid-checkbox-column>
                                    <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>

                                    <kendo-grid-column field="accountId" title="Account Id"></kendo-grid-column>
                                    <kendo-grid-column field="paymentId" title="Payment Id"></kendo-grid-column>
                                    <kendo-grid-column field="transferId" title="Transfer Id"></kendo-grid-column>
                                    <kendo-grid-column field="amount" title="Amount" filter="numeric">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{ dataItem.billAmount !=undefined ? (dataItem.billAmount| currency:
                                            'INR':'symbol':'1.2-2'): '' }}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="orderId" title="Order Id"
                                        [hidden]="true"></kendo-grid-column>

                                    <kendo-grid-column field="paymentDate" title="Payment Date" filter="date"
                                        [hidden]="true">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{dataItem.paymentDate | date: 'dd MMM yyyy hh:mm a'}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="settlementDate" title="Settlement Date" filter="date"
                                        [hidden]="true">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{dataItem.settlementDate | date: 'dd MMM yyyy hh:mm a'}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="transferDate" title="Transfer Date" filter="date"
                                        [hidden]="true">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{dataItem.transferDate | date: 'dd MMM yyyy hh:mm a'}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="settlementStatus"
                                        title="Settlement Status"></kendo-grid-column>
                                    <kendo-grid-column field="settlementId" title="Settlment Id"></kendo-grid-column>
                                    <kendo-grid-column field="paymentStatus" title="Payment Status"></kendo-grid-column>
                                    <kendo-grid-excel fileName="Settlement.xlsx"
                                        sheetName="Settlement"></kendo-grid-excel>

                                </kendo-grid>
                            </div>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="projectName" title="Project Name"></kendo-grid-column>

                            <kendo-grid-column field="accountId" title="Account Id"></kendo-grid-column>
                            <kendo-grid-column field="paymentId" title="Payment Id"></kendo-grid-column>
                            <kendo-grid-column field="transferId" title="Transfer Id"></kendo-grid-column>
                            <kendo-grid-column field="orderId" title="Order Id" [hidden]="true"></kendo-grid-column>

                            <kendo-grid-column field="paymentDate" title="Payment Date" filter="date" [hidden]="true">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.paymentDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="transferDate" title="Transfer Date" filter="date" [hidden]="true">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.transferDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column field="paymentStatus" title="Payment Status">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span *ngIf="dataItem.paymentStatus  === 'In Progress'" class="text-success"> {{
                                        dataItem.paymentStatus }}</span>
                                    <span *ngIf="dataItem.paymentStatus === 'Success'" class="text-success">{{
                                        dataItem.paymentStatus
                                        }}</span>
                                    <span *ngIf="dataItem.paymentStatus === 'Failed'" class="text-success">{{
                                        dataItem.paymentStatus
                                        }}</span>


                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="amount" title="Amount" filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.amount | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>

                            </kendo-grid-column>
                            <kendo-grid-column field="settlementDate" title="Settlement Date" filter="date"
                                [hidden]="true">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.settlementDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="settlementStatus" title="Settlement Status">
                                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                    <span *ngIf="dataItem.settlementStatus  === 'Paid'" class="text-success"> {{
                                        dataItem.settlementStatus }}</span>
                                    <span *ngIf="dataItem.settlementStatus === 'Success'" class="text-success">{{
                                        dataItem.settlementStatus
                                        }}</span>
                                    <span *ngIf="dataItem.settlementStatus === 'captured'" class="text-danger">{{
                                        dataItem.settlementStatus
                                        }}</span>
                                    <span *ngIf="dataItem.settlementStatus === 'UnPaid'" class="text-danger">{{
                                        dataItem.settlementStatus
                                        }}</span>

                                </ng-template> -->


                            </kendo-grid-column>



                            <kendo-grid-column field="settlementId" title="Settlment Id"></kendo-grid-column>
                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <!-- Edit User Icon -->
                                        <span *ngIf="dataItem.settlementId ==''" kendoTooltip title="Check Status"
                                            (click)="checkStaus(dataItem)" style="cursor: pointer;" class="me-3">
                                            <i class="fas fa-check text-success"></i>
                                        </span>

                                        <!-- Delete User Icon -->
                                        <span kendoTooltip *ngIf="dataItem.transferId ==''" title="Retry Settlement"
                                            (click)="retrySettlment(dataItem)" style="cursor: pointer;">
                                            <i class="fas fa-redo text-danger"></i>

                                        </span>
                                        <!--  <span kendoTooltip *ngIf="dataItem.transferId ==''" title="Create Transfer"
                                            (click)="createTransfer(dataItem)" style="cursor: pointer;">
                                            <i class="fas fa-redo text-warning"></i>

                                        </span> -->
                                    </div>
                                </ng-template>
                                <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span kendoTooltip title=" View Bill Details" (click)="settlement(dataItem)"
                                                style="cursor: pointer; font-size: 20px;">
                                                <i class="fas fa-info-circle text-success"></i>
                                        </span>
                                </ng-template> -->
                            </kendo-grid-command-column>

                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>