import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Master } from '../../store/Masters/masters.models';
import { Utilites } from '../../store/Utilities/utilities.models';

@Injectable({ providedIn: 'root' })
export class UtilitesService {
  constructor(private http: HttpClient, private router: Router) { }

  getUtilities(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = ''
  ): Observable<any> {
    let url;
    if (searchTerm) {
      url = `${AppConfigData.getMastersUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchTerm}`;
    } else {
      url = `${AppConfigData.getMastersUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createUtilities(masterData: Master): Observable<any> {
    let request = {
      typeId: masterData.type,
      name: masterData.name,
    };
    return this.http.post<any>(AppConfigData.createMastersUrl, request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateUtilities(masterData: Master, id: any): Observable<any> {
    let request = {
      typeId: masterData.type,
      name: masterData.name,
    };

    return this.http
      .post<any>(AppConfigData.updateMastersUrl + id, request)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteMaster(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteMastersUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }



  getGeatewayList(id: any) {
    return this.http.get<any>(AppConfigData.gatewayInformation + id,).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
